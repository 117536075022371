import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { Button, ListItemText } from '@mui/material';
import ClaimService from 'services/ClaimService';


// project import
import MainCard from 'components/MainCard';

// assets
import dateFormat from "dateformat";
import { format as currencyFormatter } from 'currency-formatter';
import { fetcher } from 'utils/axios';
import dayjs from 'dayjs';
import FileUploaded from 'components/FileUploaded';
import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import { uploadDocuments } from 'services/DocumentsService';

export default function ExpandingClaimDetail({ data }) {
  const { t } = useTranslation();
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [policyDetail, setPolicyDetail] = useState();
  const [localFiles, setLocalFiles] = useState([]);
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [showUploads, setShowUploads] = useState(true)

  useEffect(() => {
    // Get policy details
    fetcher(`/policies/detail/${data.policy_id}`).then(data => setPolicyDetail(data));
  }, [data]);

  const renderStatus = (value) => {
    switch (value) {
      case 'OPEN':
        return <Chip color="primary" label="En cours" size="Default" variant="light" />; 
      case 'REJECTED':
        return <Chip color="error" label="Refusé" size="Default" variant="light" />; 
      case 'APPROVED':
        return <Chip color="success" label="Accepté" size="Default" variant="light" />; 
      case 'AWAITING_CLIENT':
        return <Chip color="secondary" label="En attente" size="Default" variant="light" />; 
        case 'CANCELLED':
          return <Chip color="info" label="Annulé" size="Default" variant="light" />; 
      default:
        return <Chip color="default" label={`Statut inconnu: ${value}`} size="Default" variant="light" />; 
    }
  };
  

  const renderPolicyStatus = (value) => {
    switch (value) {
      case 'EXPIRED':
        return <Chip color="error" label={t('claims.status.expired')} size="Default" variant="light" />;
      case 'ACTIVE':
        return <Chip color="success" label={t('claims.status.active')} size="Default" variant="light" />;
      default:
        return <Chip color="primary" label={t(`status.${value.toLowerCase()}`)} size="Default" variant="light" />;
    }
  };

  const renderClaimButton = (value) => {

    return <Button variant="contained" color="error" onClick={cancelClaim}>{t('claims.buttons.cancelClaim')}</Button>;
    {/*
    switch (value) {
      case 'APPROVED':
      case 'CLAIMED':
        return <Button variant="contained" color="secondary" onClick={underConstruction}>{t('claims.buttons.checkTransaction')}</Button>;
      case 'REJECTED':
        return <Button variant="contained" color="primary" onClick={underConstruction}>{t('claims.buttons.openNewClaim')}</Button>;
      case 'OPEN':
      case 'PROCESSING':
        return <Button variant="contained" color="error" onClick={underConstruction}>{t('claims.buttons.cancelClaim')}</Button>;
      default:
        return <Button variant="contained" color="secondary" onClick={underConstruction}>{t('claims.buttons.default')}</Button>;
    }*/}
  };

  const generateRelationshipLabel = (relationship) => {
    switch (relationship) {
      case "client":
        return "Client lui-même";
      case "parent":
        return "Parent";
      case "spouse":
        return "Conjoint(e)";
      case "sibling":
        return "Frère/soeur";
      case "other":
        return "Autre";
      default:
        return "Inconnu";
    }
  };

  const generateClaimTypeLabel = (claimTypeId) => {
    const claimTypeMapping = {
      LICENCEMENT: "Licenciement",
      SERIOUS_ILLNESS: "Maladie grave",
      COMPLICATED_BIRTH: "Naissance compliquée",
      EXPROPRIATION: "Expropriation",
      HARASSMENT: "Harcèlement",
      VIOLENCE: "Violence",
      WORK_HARASSMENT: "Harcèlement au travail",
      SERIOUS_ACCIDENT: "Accident grave",
      NATURAL_EVENT: "Événement naturel",
      BURN_OUT: "Burn-out",
      TERRORIST_ATTACK: "Attentat terroriste",
      DEATH_PET: "Décès d'un animal de compagnie",
      DEATH_RELATIVE: "Décès d'un proche",
      FIRE: "Incendie",
      TRAUMA_WITNESS: "Témoin de traumatisme",
      THEFT: "Vol"
    };
  
    return claimTypeMapping[claimTypeId] || "Type inconnu"; 
  };

  const underConstruction = () => {
    alert(t('claims.alerts.underConstruction'));
  };

  const cancelClaim = async () => {
    try {
      const confirmCancel = window.confirm("Êtes-vous sûr de vouloir annuler ce sinistre ? Cette action est irréversible.");
      if (!confirmCancel) {
        return;
      }
      if (!data || !data.id) {
        alert(t('claims.alerts.invalidClaim'));
        return;
      }


      const response = await ClaimService.cancelClaim(data.id);

      alert("Le sinistre a été annulé avec succès.");

      window.location.reload();
    } catch (error) {
      console.error('Error canceling claim:', error);

      if (error.response && error.response.data) {
        alert(`${t('claims.alerts.cancelFailed')}: ${error.response.data.message}`);
      } else {
        alert(t('claims.alerts.cancelFailed'));
      }
    }
  };

  

  const currencyFormatter = (value, { code = 'EUR' } = {}) => {
    if (value === null || value === undefined) {
      return '—';
    }

    const formattedValue = new Intl.NumberFormat('fr-FR', {
      style: 'currency',
      currency: code,
      minimumFractionDigits: 2,
    }).format(value);

    return formattedValue.replace(/\u202F/g, '\u00A0');
  };

  const handleUploadFiles = ({ data, isError = false }) => {
    setFileRecords(data);
    setFilesError(isError);
  };
  const handleRemoveFile = (fileName) => {
    setLocalFiles((prev) => prev.filter((file) => file.name !== fileName));
  }


  const handleAddFiles = (newFiles) => {
    setLocalFiles((prev) => [...prev, ...newFiles]);
  };

  const doUpload = async () => {
    setShowUploads(false);

    try {
       

        const uploadResponse = await uploadDocuments(localFiles, data.id);

        const uploadedFiles = uploadResponse.data.records;

        data.documents.push(...uploadedFiles);

        const comment = 'Documents uploaded by client';

        const openClaimResponse = await ClaimService.openClaim(data.id, comment);

        if (openClaimResponse) {
            data.claim_status_id = 'OPEN'; 
            alert('Le statut du sinistre a été mis à jour avec succès à "Ouvert".');
            window.location.reload();
        } else {
            alert('Erreur lors de la mise à jour du statut du sinistre.');
        }

        setLocalFiles([]);
        setTimeout(() => setShowUploads(true), 1000);

    } catch (error) {
        alert('Une erreur s\'est produite. Veuillez réessayer.');
    } finally {
        setShowUploads(true);
    }
};




  return (
    <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }}>
      <Grid item xs={12} sm={5} md={4} xl={3.5}>
        <MainCard title={t('claims.policyDetails')}>
          {policyDetail && (
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.policyNumber')}</Typography>
                      <Typography variant="h3">
                        {policyDetail.amendment_number}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
             
              <ListItem divider={!matchDownMD}>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">{t('claims.registrationDate')}</Typography>
        <Typography>{dateFormat(new Date(policyDetail.payment_date), 'dd-MM-yyyy  HH:mm')}</Typography>
      </Stack>
    </Grid>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">{t('claims.expirationDate')}</Typography>
        <Typography>
          {policyDetail.expiration_date
            ? dateFormat(new Date(policyDetail.expiration_date), 'dd-MM-yyyy  HH:mm') 
            : dateFormat(new Date(new Date(policyDetail.payment_date).setFullYear(new Date(policyDetail.payment_date).getFullYear() + 1)), 'dd-MM-yyyy  HH:mm')} 
        </Typography>
      </Stack>
    </Grid>
  </Grid>
</ListItem>

              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.paymentFrequency')}</Typography>
                      <Typography>{policyDetail.payment_frequency}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          )}
        </MainCard>
      </Grid>
      <Grid item xs={12} sm={7} md={8} xl={8.5}>
        <Stack spacing={2.5}>
          <MainCard title={t('claims.claimDetails')}>
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimNumber')}</Typography>
                      <Typography variant="h3">{data.claim_reference_number}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimAmount')}</Typography>
                      <Typography variant="h3">
                        {currencyFormatter(data.request_amount, { code: 'EUR' })}
                      </Typography>
                    </Stack>

                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.createdDate')}</Typography>
                      <Typography>{dateFormat(data.created_at, 'dd-mm-yyyy - HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.occurrenceDate')}</Typography>
                      <Typography>{dateFormat(data.occurence_date, 'dd-mm-yyyy - HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>

              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimStatus')}</Typography>
                      <Typography>{renderStatus(data.claim_status_id)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimType')}</Typography>
                      <Typography>{data.claim_type_id}</Typography>
                     </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimContent')}</Typography>
                      <Typography>{data.claim_cause}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Déclarant :</Typography>
                      <Typography>{data.claimant_first_name} {data.claimant_last_name}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Email du déclarant :</Typography>
                      <Typography>{data.claimant_email}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Numéro du déclarant :</Typography>
                      <Typography>{data.claimant_phone_number}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Relation avec le déclarant :</Typography>
                      <Typography>{generateRelationshipLabel(data.claimant_relationship)}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.evidenceDocuments')}</Typography>
                      {data.documents.length > 0 && data.documents.map((document) => (
                        <FileUploaded file={document} key={document.id} />
                      ))}

                     
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimHistory')}</Typography>
                      <Typography>
            
                        <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                          {Array.isArray(data.claim_history) && data.claim_history.length && data.claim_history.map(item => {
                            const formattedDate = dayjs(item.updated_at).format('MMM DD YYYY h:mm');
                            return (
                              <ListItem key={item.id} sx={{ px: 0, justifyContent: 'space-between' }}>
                               {renderStatus(item.new_status)}

                                <ListItemText
                                  primary={formattedDate}
                                  sx={{ display: 'flex', justifyContent: 'end' }}
                                />
                              </ListItem>
                            );
                          })}
 </List>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                </ListItem>

{data.claim_status_id === 'AWAITING_CLIENT' && (
  <ListItem divider={!matchDownMD}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack spacing={0.5}>
          <Typography color="secondary">Ajouter des documents:</Typography>
          {showUploads && (
            <>
              <MultiFileUpload
                onUpload={handleUploadFiles}
                onRemoveFile={handleRemoveFile}
                onAddFile={handleAddFiles}
              />
              <Button
                disabled={localFiles.length === 0}
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={doUpload}
              >
                Ajouter des documents
              </Button>
            </>
          )}
          {!showUploads && (
            <img
              style={{ width: 50 }}
              src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-03-511_512.gif"
              alt="Uploading..."
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  </ListItem>
)}
                       
              {data.claim_status_id !== "CANCELLED" && <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      {renderClaimButton(data.claim_status_id)}
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>}
            </List>
          </MainCard>
        </Stack>
      </Grid>
    </Grid>
  );
}

ExpandingClaimDetail.propTypes = { data: PropTypes.any };