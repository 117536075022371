// material-ui
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box'; // Import Box

// project-imports
import Logo from 'components/logo';
import AuthDivider from 'sections/auth/AuthDivider';
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthAdminLogin from 'sections/auth/auth-forms/AuthAdminLogin';

// assets
import HomeIcon from '@mui/icons-material/Home';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

// ================================|| LOGIN ||================================ //

export default function AdminLogin() {
  const { t } = useTranslation();
  const navigate = useNavigate();

  return (
    <AuthWrapper>
     <Grid container spacing={2}>

{/* Home Icon at the very top left */}
<Grid item xs={12} sx={{ position: 'relative' }}>
 
  {/* Centered Logo */}
  <Box sx={{ display: 'flex', justifyContent: 'center',  }} onClick={() => navigate('/backoffice/')}>
    <Logo 
    />
  </Box>
</Grid>
        <Grid item xs={12}>
          <AuthDivider />
        </Grid>

      
        {/* Login Form */}
        <Grid item xs={12}>
          <AuthAdminLogin/>
        </Grid>

        {/* Create Account Section closer to Login Button */}
        {/* <Grid item xs={12} sx={{ textAlign: 'center', mt: 1 }}>
          <Typography variant="body2">
            {t('login.noAccount')}{" "}
            <Link href="/sign-up" color="primary">
              {t('login.createAccount')}
            </Link>
          </Typography>
        </Grid> */}
        
      </Grid>
    </AuthWrapper>
  );
}
