import PropTypes from 'prop-types';
import { useEffect, useState, useRef } from 'react';
import { useNavigate, useParams } from 'react-router';
import { loadStripe } from '@stripe/stripe-js';

// material-ui
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import Divider from '@mui/material/Divider';
import Skeleton from '@mui/material/Skeleton';
import TableRow from '@mui/material/TableRow';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import TableContainer from '@mui/material/TableContainer';

// third-party
import ReactToPrint from 'react-to-print';
import { PDFDownloadLink } from '@react-pdf/renderer';

// project-imports
import MainCard from 'components/MainCard';
import LogoSection from 'components/logo';

import { ThemeMode } from 'config';
import ExportPDFView from 'sections/apps/invoice/export-pdf';

// assets
import { ArrowRight, Back, DocumentDownload, Printer, Share, Warning2 } from 'iconsax-react';
import SignupLayout from 'layouts/frontOffice/StyledSignupLayout';

import axiosServices, { fetcherPost } from 'utils/axios';

import dateFormat from 'dateformat';
import { format as currencyFormatter } from 'currency-formatter';
import { Alert, Button } from '@mui/material';
import { Payment } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const stripePromise = loadStripe('pk_test_51QixKiCI89TBnp7J4wpJRcw708LshGES6WDJcidkOVd6PZaPF0uUVz1CwAH4uNiaUqth3YIKHKLVTru5aMePNULm00yAsPUn8g');

function PDFIconButton({ quotation }) {
  const theme = useTheme();
  return (
    <PDFDownloadLink document={<ExportPDFView list={quotation.risks_rev} />} fileName={`${quotation.quotation_code}.pdf`}>
      <IconButton>
        <DocumentDownload color={theme.palette.mode === ThemeMode.DARK ? theme.palette.background.paper : theme.palette.text.secondary} />
      </IconButton>
    </PDFDownloadLink>
  );
}

export default function Quotation() {
  const theme = useTheme();
  const { t } = useTranslation();
  const navigation = useNavigate();
  const { id } = useParams();

  const [quotation, setQuotation] = useState(null);
  const [additionalRisks, setAdditionalRisks] = useState([]);
  const [selectedRisks, setSelectedRisks] = useState([]);

  useEffect(() => {
    getQuotation();
  }, []);

  const getRiskObjects = async (productId) => {
    try {
      const request = await axiosServices.get(`/risks/list-by-product-id/${productId}`);
      return request.data;
    } catch (error) {
      console.error('Error fetching risks:', error);
      return [];
    }
  };

  const getQuotation = async () => {
    try {
      const quotation_code = window.location.pathname.replace('/quotation/', '');
      const response = await axiosServices.get(`/quotes/get-by-code/${quotation_code}`);
      setQuotation(response.data);
      setSelectedRisks(response.data.risks_rev);
      const allRisks = await getRiskObjects(response.data.product_id);
      const additional = allRisks.filter(risk => !response.data.risks_rev.some(selected => selected.id === risk.id));
      setAdditionalRisks(additional);
    } catch (error) {
      console.error('Error fetching quotation:', error);
    }
  };

  const handleCheckout = async () => {
    const confirmation = window.confirm(t('quotation.checkoutConfirmation'));
    if (!confirmation) return;

    try {
      const clientId = quotation.client_id; // Ensure client_id is correct here
      console.log('Client ID:', clientId); // Verify this logs a valid client ID before the request

      if (!clientId) {
        console.error('Client ID is undefined');
        return;
      }

      // Make the request with client_id in headers
      const { data } = await axiosServices.post(
          '/clients/create-checkout-session',
          {
            quote_id: quotation.id,
            amount: calculateTotalTTC() / 1.2,
          },
          {
            headers: { client_id: clientId }, // Add client_id to headers here
          }
      );

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (error) {
        console.error('Stripe checkout error:', error);
        return;
      }

      const recipientEmail = quotation.client.email
      const recipientName = quotation.client.first_name + ' ' + quotation.client.last_name;

      const docusignResponse = await axiosServices.post('/docusign/send-predefined-document', {
        recipient_email: recipientEmail,
        recipient_name: recipientName,
      });

      console.log('DocuSign response:', docusignResponse.data);
      alert('Document sent for signature successfully!');
    } catch (error) {
      console.error('Error during checkout or document sending:', error);
    }
  };

  const handleAddRisk = (risk) => {
    setSelectedRisks(prevSelected => [...prevSelected, risk]);
    setAdditionalRisks(prevAdditional => prevAdditional.filter(item => item.id !== risk.id));
  };

  const calculateTotalTTC = () => {
    const totalAmount = selectedRisks.reduce((sum, risk) => sum + parseFloat(risk.price), 0);
    const taxes = totalAmount * 0.2;
    return totalAmount + taxes;
  };

  const checkoutQuotation = async () => {
    const confirmation = window.confirm(t('quotation.checkoutConfirmation'));
    if (!confirmation) return;

    try {
      const response = await axiosServices.post(`/quotes/check-out/`, { quote_id: quotation.id });
      setQuotation(response.data);
      alert(t('quotation.checkoutSuccess'));
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  const handleBack = () => {
    const confirmation = window.confirm(t('quotation.backConfirmation'));
    if (confirmation) navigation('/register-policy');
  };

  const goToMyInsurance = () => {
    navigation('/my-insuranes');
  };

  const sendEmailWithAttachments = async () => {
    try {
      if (!quotation) {
        console.error('Quotation is not available');
        return;
      }

      const emailPayload = {
        email: quotation.client.email,
        subject: `Quotation Documents for ${quotation.quote_code}`,
        message: 'Please find the attached quotation documents.',
      };

      await axiosServices.post('/quotes/send-email-with-pdfs', emailPayload);

      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };


  const componentRef = useRef(null);
  const iconColor = theme.palette.mode === ThemeMode.DARK ? theme.palette.background.paper : theme.palette.text.secondary;

  return (
    <SignupLayout>
      <MainCard content={false} style={{ width: '100%' }}>
        <Stack spacing={2.5}>
          <Box sx={{ p: 2.5, pb: 0 }}>
            <MainCard content={false} border={false} sx={{ p: 1.25, bgcolor: 'secondary.lighter' }}>
              <Stack direction="row" justifyContent="flex-end" spacing={1}>
                <ReactToPrint
                  trigger={() => (
                    <IconButton>
                      <Printer color={iconColor} />
                    </IconButton>
                  )}
                  content={() => componentRef.current}
                />
                <IconButton>
                  <Share color={iconColor} />
                </IconButton>
              </Stack>
            </MainCard>
          </Box>
          <Box sx={{ p: 2.5 }} id="print" ref={componentRef}>
            <Grid container spacing={2.5}>
              <Grid item xs={12}>
                <Stack direction={{ xs: 'column', sm: 'row' }} justifyContent="space-between">
                  <Stack spacing={0.5} style={{ textAlign: 'left' }}>
                    <Stack direction="row" alignItems='center' spacing={2}>
                      <LogoSection />
                      {(quotation && !quotation.payment_date) && <Chip label={t('quotation.unpaid')} variant="light" color="error" size="small" />}
                      {(quotation && quotation.payment_date) && <Chip label={t('quotation.paid')} variant="light" color="success" size="small" />}
                    </Stack>
                    <Stack alignItems='center' gap={2} sx={{ flexDirection: { xs: 'row', md: 'column' } }}>
                      <Typography color="secondary" sx={{ typography: { xs: 'h4', md: 'h6' } }}>{t('quotation.invoiceNumber')}</Typography>
                      <Typography sx={{ typography: { xs: 'h4' } }}>{!quotation ? <Skeleton /> : quotation.quote_code}</Typography>
                      </Stack>
                  </Stack>
                  <Box sx={{ mt: 1 }}>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: { md: "flex-end" } }}>
                      <Typography variant="subtitle1">{t('quotation.createdDate')}</Typography>
                      <Typography color="secondary">{quotation ? dateFormat(quotation.created_at, 'dd-mm-yyyy - HH:MM') : <Skeleton width={100} />}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1} sx={{ justifyContent: { md: "flex-end" } }}>
                      <Typography sx={{ overflow: 'hidden' }} variant="subtitle1">
                        {t('quotation.expirationDate')}
                      </Typography>
                      <Typography color="secondary">{quotation ? dateFormat(quotation.termination_date, 'dd-mm-yyyy - HH:MM') : <Skeleton width={100} />}</Typography>
                    </Stack>
                    {(quotation && quotation.payment_date) &&
                      <Stack direction="row" spacing={1} sx={{ justifyContent: { md: "flex-end" } }}>
                        <Typography sx={{ overflow: 'hidden' }} variant="subtitle1">
                          {t('quotation.paymentDate')}
                        </Typography>
                        <Typography color="secondary">{quotation ? dateFormat(quotation.payment_date, 'dd-mm-yyyy - HH:MM') : <Skeleton width={100} />}</Typography>
                      </Stack>
                    }
                  </Box>
                </Stack>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MainCard>
                  <Stack spacing={1}>
                    {!quotation ? (
                      <Stack spacing={0.5}>
                        <Skeleton />
                        <Skeleton width={60} />
                        <Skeleton />
                      </Stack>
                    ) : (
                      <FormControl sx={{ width: '100%', height: '120px' }}>
                        <Typography variant="h4">{quotation.business_partner.name}</Typography>
                        <Typography color="secondary">{quotation.business_partner.address_line_1}, {quotation.business_partner.city}</Typography>
                        <Typography color="secondary">{t('quotation.phoneNumber')}: {quotation.business_partner.phone_number}</Typography>
                        <Typography color="secondary">{t('quotation.email')}: {quotation.business_partner.email}</Typography>
                      </FormControl>
                    )}
                  </Stack>
                </MainCard>
              </Grid>
              <Grid item xs={12} sm={6}>
                <MainCard>
                  <Stack spacing={1}>
                    {!quotation ? (
                      <Stack spacing={0.5}>
                        <Skeleton />
                        <Skeleton width={60} />
                        <Skeleton />
                      </Stack>
                    ) : (
                      <FormControl sx={{ width: '100%', height: '120px' }}>
                        <Typography variant="h4">{quotation.client.first_name} {quotation.client.last_name}</Typography>
                        <Typography color="secondary">{quotation.client.address_line_1}, {quotation.client.city}</Typography>
                        <Typography color="secondary">{t('quotation.phoneNumber')}: {quotation.client.phone_number}</Typography>
                        <Typography color="secondary">{t('quotation.email')}: {quotation.client.email}</Typography>
                      </FormControl>
                    )}
                  </Stack>
                </MainCard>
              </Grid>

              {/* Risks List */}
              <Grid item xs={12}>
                <MainCard title={t('quotation.riskObjects')}>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>#</TableCell>
                          <TableCell>{t('quotation.name')}</TableCell>
                          <TableCell align="right">{t('quotation.price')}</TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {selectedRisks.length === 0 && (
                          <TableRow>
                            <TableCell colSpan={3}>
                              <Typography variant="body1" align="center">
                                {t('quotation.noRisksSelected')}
                              </Typography>
                            </TableCell>
                          </TableRow>
                        )}
                        {selectedRisks.map((risk, index) => (
                          <TableRow key={risk.id}>
                            <TableCell>{index + 1}</TableCell>
                            <TableCell>
                              <Typography variant="h6">{risk.risk_label}</Typography>
                              <small>
                                <em>{risk.description}</em>
                              </small>
                            </TableCell>
                            <TableCell align="right">{currencyFormatter(risk.price, { code: 'EUR' })}</TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </MainCard>
              </Grid>

              {/* Additional Risks */}
              {additionalRisks.length > 0 && (
                <Grid item xs={12}>
                  <MainCard title={t('quotation.additionalPropositions')}>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>{t('quotation.add')}</TableCell>
                            <TableCell>{t('quotation.name')}</TableCell>
                            <TableCell align="right">{t('quotation.price')}</TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {additionalRisks.map((risk, index) => (
                            <TableRow key={risk.id}>
                              <TableCell>
                                <Button onClick={() => handleAddRisk(risk)} color="primary" variant="contained" size="small">
                                  +
                                </Button>
                              </TableCell>
                              <TableCell>
                                <Typography variant="h6">{risk.risk_label}</Typography>
                                <small>
                                  <em>{risk.description}</em>
                                </small>
                              </TableCell>
                              <TableCell align="right">{currencyFormatter(risk.price, { code: 'EUR' })}</TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </MainCard>
                </Grid>
              )}

              {/* Pricing Details */}
              <Grid item xs={12} sm={6} md={8}></Grid>
              <Grid item xs={12} sm={6} md={4}>
                <Stack spacing={2}>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color={theme.palette.secondary.main}>{t('quotation.subTotal')}:</Typography>
                    <Typography >{currencyFormatter(calculateTotalTTC() / 1.2, { code: 'EUR' })}</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color={theme.palette.secondary.main}>{t('quotation.promoCode')}:</Typography>
                    <Typography>00,00 €</Typography>
                  </Stack>
                  <Stack direction="row" justifyContent="space-between">
                    <Typography color={theme.palette.secondary.main}>{t('quotation.tax')}:</Typography>
                    <Typography>00,00 €</Typography>
                  </Stack>
                  
                  <Stack direction="row" justifyContent="space-between">
                    <Typography variant="subtitle1">{t('quotation.totalTTC')}:</Typography>
                    <Typography variant="subtitle1" >{currencyFormatter(calculateTotalTTC() / 1.2, { code: 'EUR' })}</Typography>

                  </Stack>
                </Stack>
              </Grid>
            </Grid>
          </Box>

          <Grid container sx={{ p: 2, flexDirection: { xs: 'column-reverse', md: 'row' } }}>
            <Grid item xs={12} md={6} sx={{ mb: 1, pr: { md: 1 } }}>
              <Button variant="contained" color="secondary" style={{ width: '100%', height: 50 }} onClick={handleBack}>
                <Back style={{ marginRight: 5 }} /> {t('quotation.back')}
              </Button>
            </Grid>
            <Grid item xs={12} md={6} sx={{ mb: 1 }}>
              {quotation && !quotation.payment_date && (
                <Button variant="contained" color="primary" style={{ width: '100%', height: 50 }} onClick={handleCheckout}>
                  <Payment style={{ marginRight: 5 }} /> {t('quotation.checkout')}
                </Button>
              )}
              <Button
                  variant="contained"
                  color="primary"
                  style={{ marginTop: '10px', width: '100%' }}
                  onClick={sendEmailWithAttachments}
              >
                Envoyer Docs precontractuels via email
              </Button>

              {quotation && quotation.payment_date && (
                <Button variant="contained" color="primary" style={{ width: '100%', height: 50 }} onClick={goToMyInsurance}>
                  {t('quotation.goToMyInsurance')} <ArrowRight style={{ marginLeft: 5 }} />
                </Button>
              )}
            </Grid>
          </Grid>
        </Stack>
      </MainCard>
    </SignupLayout>
  );
}

PDFIconButton.propTypes = { list: PropTypes.any };
