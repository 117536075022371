import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';  
import { useDropzone } from "react-dropzone";
import { useState } from "react";
import PlaceholderContent from "./PlaceholderContent";

const DropzoneWrapper = styled("div")(({ theme }) => ({
    outline: "none",
    padding: theme.spacing(5, 1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
    border: "1px dashed ",
    borderColor: theme.palette.secondary.main,
    textAlign: "center",
    "&:hover": { opacity: 0.72, cursor: "pointer" },
}));

const FilePreview = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    padding: theme.spacing(1),
    marginBottom: theme.spacing(1),
    border: `1px solid ${theme.palette.divider}`,
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paper,
}));

export default function MultiFileUpload({ onAddFile, showList = false, type, sx, onUpload, onRemoveFile, claimType }) {
    const [files, setFiles] = useState([]);

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        multiple: true,
        onDrop: (acceptedFiles) => {

            const newFiles = acceptedFiles.filter(
                (file) => !files.some((existingFile) => 
                    existingFile.name === file.name && existingFile.lastModified === file.lastModified
                )
            );

            const updatedFiles = [...files, ...newFiles];
            setFiles(updatedFiles);
            onAddFile(newFiles);


            if (onUpload) {
                onUpload(updatedFiles);
            }
        },
    });

    const handleRemove = (fileToRemove) => {

        const updatedFiles = files.filter((file) => file.name !== fileToRemove.name);
        setFiles(updatedFiles);

        if (onRemoveFile) {
            onRemoveFile(fileToRemove.name);
        }

    };

    return (
        <Box sx={{ width: "100%", ...sx }}>
            <DropzoneWrapper
                {...getRootProps()}
                sx={{
                    ...(isDragActive && { opacity: 0.72 }),
                    ...(isDragReject && { color: "error.main", borderColor: "error.light", bgcolor: "error.lighter" }),
                }}
            >
                <input {...getInputProps()} />
                <PlaceholderContent type={type} />
            </DropzoneWrapper>

            {/* File Preview */}
            {files.length > 0 && (
                <Box sx={{ mt: 2 }}>
                    {files.map((file, index) => (
                        <FilePreview key={index}>
                            <Stack direction="row" alignItems="center" spacing={2}>
                                <InsertDriveFileIcon fontSize="large" color="action" />
                                <Stack>
                                    <Typography variant="body2" noWrap>
                                        {file.name}
                                    </Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        {file.size} bytes
                                    </Typography>
                                </Stack>
                            </Stack>
                            <Button
                                size="small"
                                onClick={() => handleRemove(file)}
                                sx={{ color: "red", fontWeight: "bold", fontSize: "1rem" }}
                            >
                                ✕
                            </Button>
                        </FilePreview>
                    ))}
                </Box>
            )}
        </Box>
    );
}

MultiFileUpload.propTypes = {
    showList: PropTypes.bool,
    type: PropTypes.any,
    sx: PropTypes.object,
    onUpload: PropTypes.func,
    onRemoveFile: PropTypes.func,
    claimType: PropTypes.string,
};
