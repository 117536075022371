// This is example of menu item without group for horizontal layout. There will be no children.

// third-party
import { FormattedMessage } from 'react-intl';

// assets
import { DocumentCode2 } from 'iconsax-react';

// type

// icons
const icons = {
  samplePage: DocumentCode2
};

// ==============================|| MENU ITEMS - SAMPLE PAGE ||============================== //


const backofficeMenu = {
  id: 'my-inventory',
  title: 'Gestion',
  type: 'group',
  children: [  
    {
      id: 'policies',
      title: 'Gestion des contrats',
      type: 'item',
      url: '/backoffice/policies',
      icon: icons.samplePage,
    },  
    {
      id: 'claims',
      title: 'Gestion des Sinistre',
      type: 'item',
      url: '/backoffice/claims',
      icon: icons.samplePage,
    },
    {
      id: 'my-complains',
      title: 'Gestion des avis',
      type: 'item',
      url: '/backoffice/complaints',
      icon: icons.samplePage,
    },
    {
      id: 'clients',
      title: 'Gestion des clients',
      type: 'item',
      url: '/backoffice/clients',
      icon: icons.samplePage,
    },
    {
      id: 'accounts',
      title: 'Gestion des comptes',
      type: 'item',
      url: '/backoffice/accounts',
      icon: icons.samplePage,
    }
  ]
};

export default backofficeMenu;
