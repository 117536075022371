import React, { useEffect, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import './CreateClaimFormLayout.css';
import InputComponent from 'components/InputComponent';
import { useTranslation } from 'react-i18next';
import { uploadDocuments } from "services/DocumentsService";

import Constants from 'common/Constants';
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Chip,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from '@mui/material';
import DateTimePickerComponent from 'components/frontOffice/DateTimePickerComponent/DateTimePickerComponent';
import dayjs from 'dayjs';
import CreateClaimChatLayout from '../CreateClaimChatLayout/CreateClaimChatLayout';
import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import ClaimService from 'services/ClaimService';
import ClientService from "services/ClientService";
import PoliciesService from 'services/PoliciesService';
import axiosServices from 'utils/axios';
import dateFormat from 'dateformat';
import { Ethereum } from 'iconsax-react';
import * as Yup from 'yup';

const CreateClaimFormLayout = ({ onClose, onRefresh }) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [claimType, setClaimType] = useState(null);
  const [claimCause, setClaimCause] = useState("");
  const [claimAmount, setClaimAmount] = useState("");
  const [inputValue, setInputValue] = useState(null);
  const [selectedPolicy, setSelectedPolicy] = useState(null);
  const [policyError, setPolicyError] = useState(false);
  const [claimError, setClaimError] = useState(false);
  const [occurrenceDate, setOccurrenceDate] = useState(dayjs());
  const client = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
  const [policies, setPolicies] = useState([]);
  const [user, setUser] = useState({});
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [conversation, setConversation] = useState()
  const [localFiles, setLocalFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [activeStep, setActiveStep] = useState(0);
  const [filteredPolicies, setFilteredPolicies] = useState([]); // Policies that can be selected
    const [claimTypeOptions, setClaimTypeOptions] = useState([]);

    const [infoError, setInfoError] = useState(false);

  const [phoneNumber, setPhoneNumber] = useState(client.phone_number);
  const [email, setEmail] = useState(client.email);
  const [firstName, setFirstName] = useState(client.first_name);
  const [lastName, setLastName] = useState(client.last_name);

  const [relationship, setRelationship] = useState("cleint"); 
  const [showQuestion, setShowQuestion] = useState(false); 


  const isDataModified = () => {
    return (
      phoneNumber !== client.phone_number ||
      email !== client.email ||
      firstName !== client.first_name ||
      lastName !== client.last_name 
    );
    
  };

  useEffect(() => {
    setShowQuestion(isDataModified());
    if (!isDataModified()) {
      setRelationship('client');
    }
  }, [phoneNumber, email, firstName, lastName]);


    const handleAddFiles = (newFiles) => {
    setLocalFiles((prev) => [...prev, ...newFiles]);
};

const handleRemoveFile = (fileName) => {
    setLocalFiles((prev) => prev.filter((file) => file.name !== fileName)); 
};
    useEffect(() => {
        fetchPolicies();
    }, []);

    useEffect(() => {
        const fetchClientIdAndPolicies = async () => {
            try {
                // Fetch client ID using ClientService
                const clientId = await ClientService.getClientIdByEmail(client.email);
                setUser({ ...client, id: clientId }); // Update the client object with fetched ID

                // Fetch policies using the clientId
                await fetchPolicies(clientId);
            } catch (error) {
                console.error("Error fetching client ID or policies:", error.message);
            }
        };

        if (client?.email) {
            fetchClientIdAndPolicies();
        }
    }, []);


    const fetchPolicies = async (clientId) => {
        try {
            // Fetch all policies
            const response = await axiosServices.get("/policies/my-policies");
            const allPolicies = response.data;
            setPolicies(allPolicies);

            // Filter eligible policies
            const eligiblePolicies = await Promise.all(
                allPolicies.map(async (policy) => {
                    try {
                        const { maxClaims, currentClaims } = await ClaimService.getPolicyClaimLimits(policy.id);
                        return maxClaims > currentClaims ? policy : null;
                    } catch (error) {
                        console.error(`Error fetching claim limits for policy ${policy.id}:`, error);
                        return null;
                    }
                })
            );

            // Remove null values and set filtered policies
            setFilteredPolicies(eligiblePolicies.filter(Boolean));
            console.log("Filtered Policies:", eligiblePolicies.filter(Boolean));

            // If a policy is already selected, fetch its events
            if (selectedPolicy) {
                await fetchEvents(clientId, selectedPolicy.id);
            }
        } catch (error) {
            console.error("Error fetching policies:", error);
        }
    };

    const renderClaimTypeMenuItems = () => {
        return claimTypeOptions.map((eventName) => (
            <MenuItem value={eventName} key={eventName}>
                {eventName}
            </MenuItem>
        ));
    };


    useEffect(() => {
        if (selectedPolicy && user.id) {
            console.log("Fetching events for selected policy:", selectedPolicy.id);
            fetchEvents(user.id, selectedPolicy.id);
        }
    }, [selectedPolicy, user.id]);

    const fetchEvents = async (clientId, policyId) => {
        try {
            if (!clientId || !policyId) {
                throw new Error("Client ID or Policy ID is missing");
            }

            const response = await PoliciesService.getEventsByPolicyId(clientId, policyId);
            setClaimTypeOptions(response.events.map((event) => event.name));
        } catch (error) {
            console.error(`Error fetching events for policy ${policyId}:`, error);
            setClaimTypeOptions([]);
        }
    };


    const handleClaimTypeChange = (event) => {
    setClaimType(event.target.value);
  };

  const handleClaimCauseChange = (event) => {
    setClaimCause(event.target.value);
  };

  

  const handleClaimAmountChange = (event) => {
    setClaimAmount(event.target.value);
  };

  const handleOccurrenceDateChange = (value) => {
    setOccurrenceDate(value);
  };

  const handleUploadFiles = ({data, isError = false}) => {
    setFileRecords(data)
    setFilesError(isError)
  }

  

  const prepareCreateClaimData = (uploadedFiles) => {
    const fileList = uploadedFiles.map((item) => item.id);


    return {
      policy_id: selectedPolicy.id,
      portfolio_id: Constants.PORTFOLIO_ID_SAMPLE,
      claim_status_id: Constants.STATUS.CLAIM.OPEN.LABEL,
      claim_cause: claimCause,
      claim_type_id: claimType,
      occurence_date: occurrenceDate,
      files: fileList,
      conversation_rev: JSON.stringify(conversation),
      claimant_first_name: firstName, 
    claimant_last_name: lastName,    
    claimant_email: email,           
    claimant_phone_number: phoneNumber,
    claimant_relationship: relationship 
    };
  };

  const steps = [
    { label: t('claims.steps.reviewInformation'), description: t('claims.steps.reviewInformationDesc') },
    { label: t('claims.steps.selectPolicy'), description: t('claims.steps.selectPolicyDesc') },
   // { label: t('claims.steps.answerQuestions'), description: t('claims.steps.answerQuestionsDesc') },
    { label: t('claims.steps.fillClaim'), description: t('claims.steps.fillClaimDesc') },
    { label: t('claims.steps.uploadEvidence'), description: t('claims.steps.uploadEvidenceDesc') }
  ];

  const handleBack = () => {
    setActiveStep(activeStep - 1);
  };

  const handleNext = () => {
    setPolicyError(false);
    setClaimError(false);
    setInfoError(false);

    if (activeStep === 0) {
      const isMissingRequiredField =
        !phoneNumber.trim() ||
        !email.trim() ||
        !firstName.trim() ||
        !lastName.trim() ||
        (showQuestion && !relationship.trim()); 
  
      if (isMissingRequiredField) {
        setInfoError(true);
        return;
      }
    }

    if (activeStep === steps.length - 1) {
        if (localFiles.length === 0) {
            setFilesError(true);
            return;
        }
        createNewClaim();
    } else {
        if (activeStep === 1 && selectedPolicy === null) {
            setPolicyError(true);
            return;
        }
        if (activeStep === 2) {
            if (
                
                claimCause.length === 0 ||
                !occurrenceDate ||
                !claimType
            ) {
                setClaimError(true);
                return;
            }
        }
        setActiveStep(activeStep + 1);
    }
};


  

const createNewClaim = async () => {
  setLoading(true);
  let interval;
  try {
      let dots = "";
      interval = setInterval(() => {
          dots = dots.length < 3 ? dots + "." : "";
          setLoadingText(`loading${dots}`);
      }, 500);

      const uploadResponse = await uploadDocuments(localFiles);
      debugger;
      const uploadedFileRecords = uploadResponse.data.records;

      const data = prepareCreateClaimData(uploadedFileRecords);

      const response = await ClaimService.createNewClaim(data);

      if (response) {
          setActiveStep((prevStep) => prevStep + 1);
          onRefresh();

          try {
            const { id: claimId, client_id: clientId } = response;
            if (!claimId || !clientId) {
              throw new Error("Missing claimId or clientId in server response.");
            }

            console.log("client id:",clientId)
            // const emailResponse = await ClaimService.sendEmailForClaim(claimId,clientId);
            // console.log("Email sent successfully:", emailResponse);
          } catch (emailError) {
            console.error("Error sending email notification:", emailError);
            alert("Claim created, but failed to send email notification.");
          }
        } else {
          throw new Error("Claim creation failed: No response from server");
        }
  } catch (err) {
      console.error("Error during claim creation:", err);
      alert("An error occurred. Please try again.");
  } finally {
      clearInterval(interval);
      setLoading(false);
      setLoadingText("Loading");
  }
};



  const validationSchema = Yup.object({
    phoneNumber: Yup.string()
      .required(t('complaints.errorRequiredPhone')),
    emailContact: Yup.string()
      .email(t('complaints.errorInvalidEmail'))
      .required(t('complaints.errorRequiredEmail')),
    complaintDescription: Yup.string()
      .required(t('complaints.errorRequiredComplaintDescription')),
  });

  const renderInformation = () => {
    return (
        <List>
          {infoError && <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
                {t('claims.errors.fillAllData')}
                </Alert>}
          <ListItem>
          
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.firstName')}
                  customMargin={true}
                  value={firstName}
                onChange={(e) => setFirstName(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.lastName')}
                  customMargin={true}
                  value={lastName}
                  onChange={(e) => setLastName(e.target.value)}
                />
              </Grid>
            </Grid>
          </ListItem>
        {client.client_type === Constants.CLIENT_TYPE.PROFESSIONAL &&
            <ListItem>
              <Grid container spacing={{ xs: 5, md: 2 }}>
                <Grid item xs={12} sm={6}>
                  <InputComponent
                  label={t('claims.client.companySIRET')}
                    customMargin={true}
                    value={client.company_id}
                    readOnly={true}
                  />
                </Grid>
                <Grid item xs={12} sm={6}>
                  <InputComponent
                  label={t('claims.client.companyName')}
                    customMargin={true}
                    value={client.company_name}
                    readOnly={true}
                  />
                </Grid>
              </Grid>
            </ListItem>
        }
          
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.phoneNumber')}
                  customMargin={true}
                  value={phoneNumber}
                onChange={(e) => setPhoneNumber(e.target.value)}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <InputComponent
                label={t('claims.client.email')}
                  customMargin={true}
                  value={email}
                onChange={(e) => setEmail(e.target.value)}
                />
              </Grid>
            </Grid>
          </ListItem>
          {showQuestion && (
          <ListItem>
            <Grid container spacing={{ xs: 5, md: 2 }}>
              <Grid item xs={12} sm={12}>
              
                <FormControl fullWidth>
                  <InputLabel>Quelle est votre relation avec l'assuré principal?</InputLabel>
                  <Select
                    value={relationship}
                    onChange={(e) => setRelationship(e.target.value)}
                  >
                    <MenuItem value="spouse">Conjoint(e)</MenuItem>
                    <MenuItem value="sibling">Frère/soeur</MenuItem>
                    <MenuItem value="parent">Parent</MenuItem>
                    <MenuItem value="other">Autre</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </ListItem>
        )}
        </List>
    );
  }

  const renderPolicyDetails = () => {
    return (
        <List>
            <ListItem>
                <Grid container spacing={{ xs: 5, md: 2 }}>
                    <Grid item xs={12} sm={12}>
                        {policyError && (
                            <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
                                {t("claims.errors.selectPolicy")}
                            </Alert>
                        )}
                        <Autocomplete
                            fullWidth
                            disablePortal
                            id="policy-autocomplete"
                            options={
                                filteredPolicies.length > 0
                                    ? filteredPolicies.map((policy) => ({
                                        id: policy.id,
                                        label: `${policy.amendment_number} (${dateFormat(
                                            policy.inception_date,
                                            "dd-mm-yyyy"
                                        )})`,
                                    }))
                                    : []
                            }
                            renderInput={(params) => (
                                <TextField {...params} placeholder={t("claims.placeholders.enterPolicyNumber")} />
                            )}
                            value={selectedPolicy}
                            onChange={(event, newValue) => {
                                setSelectedPolicy(newValue);
                            }}
                            inputValue={inputValue}
                            onInputChange={(event, newInputValue) => {
                                setInputValue(newInputValue);
                            }}
                        />
                    </Grid>
                </Grid>
            </ListItem>
        </List>
    );
  }
  
  const handleSaveConversation = (data) => setConversation(data)

  const renderQuestionnaire = () => {
    return (
      <>
        <Chip color="error" label={t('claims.messages.waitingForAIDevelopment')} size="default" variant="light" />
        <CreateClaimChatLayout step={activeStep} theme={theme} onSaveConversation={handleSaveConversation} />
      </>
    );
  }

  const renderClaimForm = () => {
    return (
      <div style={{ marginTop: 20, marginBottom: 20 }}>
        <List style={{ marginTop: 20, marginBottom: 20 }} sx={{ py: 0 }}>
          {claimError && <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('claims.errors.fillAllData')}
          </Alert>}
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography>
                    <DateTimePickerComponent
                      required
                      label={t('claims.layouts.pages.myClaims.createClaimForm.occurrenceDate')}
                      value={occurrenceDate}
                      type={Constants.DATETIME_PICKER.TYPE.DATE_PICKER}
                      onChange={handleOccurrenceDateChange}
                      disableFuture
                    />
                  </Typography>
                </Stack>
              </Grid>
              <Grid item xs={12} md={6}>
                <Stack spacing={0.5}>
                  <Typography>
                    <FormControl fullWidth>
                      <InputLabel
                        id="claimTypeSelectLabel"
                        sx={{ color: 'gray' }}
                      >
                        {t('claims.layouts.pages.myClaims.createClaimForm.claimType')}
                      </InputLabel>
                      <Select
                        required
                        id="claimTypeSelect"
                        value={claimType}
                        label={t('claims.layouts.pages.myClaims.createClaimForm.claimType')}
                        onChange={handleClaimTypeChange}
                      >
                        {renderClaimTypeMenuItems()}
                      </Select>
                    </FormControl>
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          <ListItem>
            <Grid container spacing={3}>
              <Grid item xs={12} md={12}>
                <Stack spacing={0.5}>
                  <Typography>
                    <TextField
                      id="outlined-multiline-static"
                      fullWidth
                      label={t('claims.layouts.pages.myClaims.createClaimForm.letterOfClaim')}
                      multiline
                      rows={5}
                      value={claimCause}
                      defaultValue=""
                      onChange={handleClaimCauseChange}
                    />
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </ListItem>
          
        </List>
      </div>
    );
  };

  const renderUpload = () => {
    return (
      <>
        {filesError && (
          <Alert sx={{ my: 0.5 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('claim.evidence')}
          </Alert>
        )}
        <MultiFileUpload onUpload={handleUploadFiles} onRemoveFile={handleRemoveFile} claimType={claimType}  onAddFile={handleAddFiles}/>
      </>
    );
  };

  const handleQuit = () => {
    onClose();
  };

  return (
    <div className="create-claim-form-layout" style={{ width: 600 }}>
      <Stepper activeStep={activeStep} orientation="vertical" size="lg">
        {steps.map((step, index) => (
          <Step key={step.label}>
            <StepLabel>{step.label}</StepLabel>
            <StepContent>
              <Typography>{step.description}</Typography>
              <Box>
                {index === 0 && renderInformation()}
                {index === 1 && renderPolicyDetails()}
                {/*{{index === 2 && renderQuestionnaire()}*/}
                {index === 2 && renderClaimForm()}
                {index === 3 && renderUpload()}
              </Box>
              <Box sx={{ mb: 2 }}>
                <div>
                <Button
  variant="contained"
  onClick={handleNext}
  sx={{ mt: 1, mr: 1 }}
  color={index === steps.length - 1 ? 'primary' : 'secondary'}
  disabled={loading} 
>
  {loading && index === steps.length - 1
    ? loadingText
    : index === steps.length - 1
    ? t('claims.buttons.submitNewClaim') 
    : t('claims.buttons.continueNextStep')}
</Button>

                  <Button disabled={index === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                    {t('claims.buttons.back')}
                  </Button>
                </div>
              </Box>
            </StepContent>
          </Step>
        ))}
      </Stepper>
      {activeStep === steps.length && (
        <Box sx={{ pt: 2 }}>
          <Typography sx={{ color: 'success.main' }}>{t('claims.messages.claimSubmitted')}</Typography>
          <Button
              variant="outlined"
              color="error"
              onClick={handleQuit} 
              sx={{ mt: 2 }}
            >
              Quitter
            </Button>
        </Box>
      )}
    </div>
  );
};

CreateClaimFormLayout.propTypes = {};

CreateClaimFormLayout.defaultProps = {};

export default CreateClaimFormLayout;
