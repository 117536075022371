import serviceLinks from 'common/Links.json';
import Headers from 'common/types/Headers';
import { fetcher, fetcherPost, fetcherPut } from 'utils/axios';
import axios from 'axios';

const headers = new Headers();

class ComplaintService {
  /**
   * Fetch complaint list by client ID
   * @param {string} clientId - The client ID
   * @returns {Promise<any>} - List of complaints
   * @throws {Error} - If client ID is missing or request fails
   */
  static async getComplaintListByClientId(clientId) {
    if (!clientId) {
      throw new Error('Client ID is required');
    }

    const url = serviceLinks['Complaints']['list-by-client'].url.replace('{client_id}', clientId);
    try {
      return await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);
    } catch (err) {
      console.error(`Error fetching complaint list for client ID: ${clientId}`, err);
      throw new Error('Failed to fetch complaints by client ID');
    }
  }

  /**
   * Fetch logged-in user's complaint list
   * @returns {Promise<any>} - List of user's complaints
   * @throws {Error} - If request fails
   */
  static async getMyComplaintList() {
    const url = serviceLinks['Complaints']['my-complaints'].url;
    try {
      return await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);
    } catch (err) {
      console.error('Error fetching user complaints', err);
      throw new Error('Failed to fetch your complaints');
    }
  }

  static async getComplainList() {
    const url = serviceLinks['Complaints']['get-all'].url;
    try {
      return await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);
    } catch (err) {
      throw err;
    }
  }

  /**
   * Fetch complaint details by complaint ID
   * @param {string} complaintId - The complaint ID
   * @returns {Promise<any>} - Complaint details
   * @throws {Error} - If complaint ID is missing or request fails
   */
  static async getComplaintDetailByComplaintId(complaintId) {
    if (!complaintId) {
      throw new Error('Complaint ID is required');
    }

    const url = serviceLinks['Complaints']['detail'].url.replace('{complaint_id}', complaintId);
    try {
      return await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);
    } catch (err) {
      console.error(`Error fetching complaint details for ID: ${complaintId}`, err);
      throw new Error('Failed to fetch complaint details');
    }
  }

  /**
   * Create a new complaint
   * @param {Object} params - Complaint data
   * @returns {Promise<any>} - Response from the server
   * @throws {Error} - If parameters are missing or request fails
   */
  static async createNewComplaint(params) {
    if (!params) {
      throw new Error('Complaint data is required');
    }

    const url = serviceLinks['Complaints']['create'].url;
    try {
      return await fetcherPost([url, params]);
    } catch (err) {
      console.error('Error creating a new complaint', err);
      throw new Error('Failed to create a new complaint');
    }
  }

  /**
   * Cancel a complaint by its ID
   * @param {string} complaintId - The complaint ID
   * @returns {Promise<any>} - Updated complaint data
   * @throws {Error} - If complaint ID is missing or request fails
   */
  static async cancelComplaintById(complaintId) {
    if (!complaintId) {
      throw new Error('Complaint ID is required');
    }

    const url = serviceLinks['Complaints']['cancel'].url.replace('{complaint_id}', complaintId);

    console.log('Cancel Complaint Full URL:', url); // Log the full URL for debugging

    try {
      const response = await fetcherPut([url, { headers: headers.GetHeaders() }]);

      // Check for status_id instead of status
      if (!response || !response.status_id) {
        throw new Error('Invalid response structure: Missing status_id');
      }

      console.log('Updated Complaint:', response); // Log the response
      return response; // Return the full response or a specific field as needed
    } catch (err) {
      console.error(`Error canceling complaint with ID: ${complaintId}`, err);
      throw new Error('Failed to cancel the complaint');
    }
  }

  static async sendEmailForComplaint(complaintId, clientId) {
    if (!complaintId || !clientId) {
      return Promise.reject(new Error('Complaint ID and email are required.'));
    }
    try {
      const response = await axios.post(`http://localhost:3001/complaints/send-email-for-complaint`, {
        complaintId,
         clientId,
       
      });
      return response.data;
    } catch (error) {
      console.error('Error sending email for complaint:', error.response?.data || error.message);
      throw error.response?.data || new Error('Failed to send email for complaint.');
    }
  }

  static async updateComplaintLevelById(complaintId, levelId) {
    const url = serviceLinks['Complaints']['update-level'].url.replace('{complaint_id}', complaintId);

    try {
      return await fetcherPut([url, { complaint_level_id: levelId }, { headers: headers.GetHeaders() }]);
    } catch (err) {
      throw err;
    }
  }
}

export default ComplaintService;
