import { createContext, useCallback, useEffect, useReducer } from 'react';

// third-party
import { Chance } from 'chance';
import { jwtDecode } from 'jwt-decode';

// reducer - state management
import { LOGIN, LOGOUT, UPDATE_PROFILE, ADMIN_USER } from 'store/reducers/actions';
import authReducer from 'store/reducers/auth';
import serviceLinks from 'common/Links.json'


// project-imports
import Loader from 'components/Loader';
import axios, { fetcher, fetcherPut } from 'utils/axios';
import APILinks from "common/Links.json"
import Headers from 'common/types/Headers';
import Constants from 'common/Constants';
import userAPI from '../services/AvektoiAPIClient';

const headers = new Headers();
const chance = new Chance();

// constant
const initialState = {
  isLoggedIn: false,
  isAdminLoggedIn: false,
  isInitialized: false,
  isComplaintManager: false,
  user: null,
  profile: null
};

const verifyToken = (CLIENT_AUTH_TOKEN) => {
  if (!CLIENT_AUTH_TOKEN) {
    return false;
  }
  const decoded = jwtDecode(CLIENT_AUTH_TOKEN);

  /**
   * Property 'exp' does not exist on type '<T = unknown>(token: string, options?: JwtDecodeOptions | undefined) => T'.
   */
  return decoded.exp > Date.now() / 1000;
};

const setSession = (CLIENT_AUTH_TOKEN) => {
  if (CLIENT_AUTH_TOKEN) {
    localStorage.setItem('CLIENT_AUTH_TOKEN', CLIENT_AUTH_TOKEN);    
    axios.defaults.headers['CLIENT_AUTH_TOKEN'] = `${CLIENT_AUTH_TOKEN}`;
  } else {
    localStorage.removeItem('CLIENT_AUTH_TOKEN');
    localStorage.removeItem(Constants.CLIENT_OBJECT);
    sessionStorage.removeItem('clientObject');
    axios.defaults.headers['CLIENT_AUTH_TOKEN'] = null;
  }
};

const setAdminSession = (ACCESS_ADMIN_TOKEN) => {
  if (ACCESS_ADMIN_TOKEN) {
    localStorage.setItem('ACCESS_ADMIN_TOKEN', ACCESS_ADMIN_TOKEN);    
    axios.defaults.headers['ACCESS_ADMIN_TOKEN'] = `${ACCESS_ADMIN_TOKEN}`;
  } else {
    localStorage.removeItem('ACCESS_ADMIN_TOKEN');
    localStorage.removeItem(Constants.ADMIN_OBJECT);
    sessionStorage.removeItem(Constants.ADMIN_OBJECT);
    axios.defaults.headers['ACCESS_ADMIN_TOKEN'] = null;
  }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //

const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
  const [state, dispatch] = useReducer(authReducer, initialState);
  useEffect(() => {
    const init = async () => {
      try {
        const CLIENT_AUTH_TOKEN = localStorage.getItem('CLIENT_AUTH_TOKEN');
        if (CLIENT_AUTH_TOKEN) {
          setSession(CLIENT_AUTH_TOKEN);          
          const response = await axios.get('/clients/check-token');
          const user  = response.data;
          dispatch({
            type: LOGIN,
            payload: {
              isLoggedIn: true,
              user
            }
          });
        } else {
          dispatch({
            type: LOGOUT
          });
        }

        const adminObject = localStorage.getItem('ADMIN_OBJECT') ? JSON.parse(localStorage.getItem('ADMIN_OBJECT')) : null;

        if (adminObject) {
          dispatch({
            type: ADMIN_USER,
            payload: {
              isAdminLoggedIn: true,
              isComplaintManager: adminObject.is_complaint_manager || false,
            }
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: LOGOUT
        });
      }
    };

    init();
  }, []);

  const login = async (email, password) => {
    const response = await axios.post(APILinks.login, {username: email, password})
    const { login_token } = response.data;
    setSession(login_token);
    localStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(response.data))
    dispatch({
      type: LOGIN,
      payload: {
        isLoggedIn: true,
        user: response.data,
      }
    });
  };

  const adminlogin = async (username, password) => {
    const response = await axios.post(APILinks.adminLogin, {username: username, password})
    const { login_token } = response.data;
    setAdminSession(login_token);
    if(login_token) window.location = "/backoffice/"
    localStorage.setItem(Constants.ADMIN_OBJECT, JSON.stringify(response.data))
    dispatch({
      type: LOGIN,
      payload: {
        isAdminLoggedIn: true,
        user: response.data,
      }
    });
  };

  const register = async (email, password, firstName, lastName) => {
    // todo: this flow need to be recode as it not verified
    const id = chance.bb_pin();
    const response = await axios.post('/api/account/register', {
      id,
      email,
      password,
      firstName,
      lastName
    });
    let users = response.data;

    if (window.localStorage.getItem('users') !== undefined && window.localStorage.getItem('users') !== null) {
      const localUsers = window.localStorage.getItem('users');
      users = [
        ...JSON.parse(localUsers),
        {
          id,
          email,
          password,
          name: `${firstName} ${lastName}`
        }
      ];
    }

    window.localStorage.setItem('users', JSON.stringify(users));
  };

  const logout = () => {
    setSession(null);
    dispatch({ type: LOGOUT });
  };

  const resetPassword = (email) => userAPI.resetPassword(email);

  const getMyProfile = useCallback(async (clientId) => {
    const url = serviceLinks['Clients']['my-profile'].url.replace('{id}', clientId);

    try {
      const profile = await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);

      // Dispatch action to update the profile in the state
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          profile
        }
      });

      return profile; // Optional: Return the profile data if needed
    } catch (err) {
      console.error('Failed to fetch profile:', err);
      // Optionally, you can dispatch an error action or handle the error in another way
    }
  }, []);

  const updateMyProfile = async (clientId, updatedData) => {
    if (updatedData.date_of_birth) {
      const [day, month, year] = updatedData.date_of_birth.split('/');
      updatedData.date_of_birth = `${year}-${month}-${day}`; 
  }
    if (!clientId || !updatedData) {
        throw new Error("Client ID or updated data is missing.");
    }

    const url = serviceLinks['Clients']['update-profile'].url.replace('{id}', clientId);

    try {
        const updatedProfile = await fetcherPut([url, updatedData]);

        dispatch({
            type: UPDATE_PROFILE,
            payload: {
                profile: updatedProfile,
            },
        });
        localStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedProfile));

        return updatedProfile;
    } catch (err) {
        console.error("Failed to update profile:", {
            message: err.message,
            stack: err.stack,
        });
        throw new Error("Profile update failed.");
    }
};


  if (state.isInitialized !== undefined && !state.isInitialized) {
    return <Loader />;
  }

  return <JWTContext.Provider value={{ ...state, login, adminlogin, logout, register, resetPassword, updateMyProfile, getMyProfile }}>{children}</JWTContext.Provider>;
};

export default JWTContext;
