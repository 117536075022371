import React, { useEffect, useState } from "react";
import { Stack, styled } from "@mui/system";
import InputComponent from "../../../../components/InputComponent";
import { useTranslation } from "react-i18next";
import userAPI from "../../../../services/AvektoiAPIClient";
import { Box, Button, Grid, Typography } from "@mui/material";
import Constants from "common/Constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "components/@extended/IconButton";
import TypeSwitcher from 'components/frontOffice/HomePageComponents/TypeSwitcher';
import Loader from "components/Loader";


export const Instructions = styled("p")({
  fontSize: "16px",
  color: "#675f63",
  textAlign: "center",
  marginBottom: "20px",
  maxWidth: "306px",
  margin: "0 auto 20px",
});

const VerifyPhoneStep = ({ nextStep, prevStep }) => {
  const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));

  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState("");
  const [error, setError] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("")
  const [selection, setSelection] = useState(clientObject.client_type);
  const [isLoading, setIsLoading] = useState(false)
 
  const toggleSelection = () => {
    const newSelection = selection === Constants.CLIENT_TYPE.PERSONAL ? Constants.CLIENT_TYPE.PROFESSIONAL : Constants.CLIENT_TYPE.PERSONAL;
    setSelection(newSelection);
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, client_type: newSelection }));
  };

  useEffect(() => {
    let clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT));
    setSelection(clientObject.client_type);
    setPhoneNumber(clientObject.phone_number)
    if (clientObject.phone_number && clientObject.isPhoneVerified) {
      nextStep({ ...clientObject });
      return;
    }
  }, [nextStep]);

  const handleNextClick = async () => {
    try {
      const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
      const phoneNumber = clientObject.phone_number;
      setIsLoading(true)
      const response = await userAPI.verifyPhone(phoneNumber, parseInt(verificationCode));
      setIsLoading(false)
      let isPhoneVerified = false
      if (response === undefined) {
        setError("");
        isPhoneVerified = true
        nextStep();
      } else if (response.message === "Invalid phone number or verification code") {
        setError("invalid");
      } else {
        setError("creation_failed");
      }
      sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify({ ...clientObject, isPhoneVerified }));
    } catch (error) {
      setError("invalid");
    }
  };

  const resendToken = async () => {
    try {
      setIsLoading(true)
      const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
      const phoneNumber = clientObject.phone_number;

      await userAPI.sendPhoneVerification(phoneNumber);
      setIsLoading(false)
      alert(t("signup.step3.tokenResent"));
    } catch (error) {
      console.error("Error resending token:", error);
    }
  };

  const handleBackClick = () => {
    prevStep();
  };

  return (
    <>
      {isLoading && <Loader />}
      {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection}  homePage={true}/>*/}
      <Typography variant="h2" style={{ marginTop: 20 }}>{t("signup.step3.title1")}</Typography>
      <div style={{ marginTop: 30 }}>
        <Typography variant="h5">{t("signup.step3.instructions1")}</Typography>
        <Typography variant="h5">{phoneNumber}</Typography>
        <Grid container>
          
          <Grid item xs={12}>
            <div style={{ maxWidth: 300 }}>
              <InputComponent
                label={t("signup.step3.verificationCode")}
                type="text"
                value={verificationCode}
                onChange={(e) => setVerificationCode(e.target.value)}
                error={error === "invalid"}
                helperText={error === "invalid" ? t("signup.step3.errorInvalidInput") : ""}
              />
            </div>
          </Grid>
        </Grid>
        <Stack direction="row" alignItems="center" justifyContent="center" columnGap={2} style={{marginBottom: 20}}>
          <Button size="large" disabled={!verificationCode} variant="contained" color="primary" style={{ width: 250 }} onClick={handleNextClick}>
            {t("buttons.confirm")}
          </Button>
        </Stack>
        <Instructions>
          {t('signup.step3.haventReceivedCode')}
          <div className="resend-link" onClick={resendToken}>
            {t('signup.step3.resendCode')}
          </div>
        </Instructions>
      </div>
    </>
  );
};

export default VerifyPhoneStep;
