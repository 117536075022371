import ComingSoon from 'pages/maintenance/coming-soon/coming-soon-old';
// ==============================|| MAIN ROUTES ||============================== //

const OtherRoutes = {
  path: '/',
  children: [
    {
      path: '/coming-soon',
      element: <ComingSoon/>,    
    }
  ]
};

export default OtherRoutes;
