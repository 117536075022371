import PropTypes from 'prop-types';
import { Fragment, useEffect, useMemo, useState } from 'react';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Tabs, Tab, Chip, Grid, Checkbox } from '@mui/material';
import { Send, TableDocument } from 'iconsax-react';

// material-ui
import { alpha, useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableCell from '@mui/material/TableCell';
import TableFooter from '@mui/material/TableFooter';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Tooltip from '@mui/material/Tooltip';
import Stack from '@mui/material/Stack';
import PoliciesService from '../../../services/PoliciesService';
import AccountService from '../../../services/AccountService';

// third-party
import { DndProvider } from 'react-dnd';
import { isMobile } from 'react-device-detect';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { TouchBackend } from 'react-dnd-touch-backend';
import {
  getCoreRowModel,
  getFilteredRowModel,
  getFacetedRowModel,
  getFacetedMinMaxValues,
  getFacetedUniqueValues,
  getPaginationRowModel,
  getSortedRowModel,
  getGroupedRowModel,
  getExpandedRowModel,
  flexRender,
  useReactTable,
  sortingFns
} from '@tanstack/react-table';
import { compareItems, rankItem } from '@tanstack/match-sorter-utils';

// project import
import MainCard from 'components/MainCard';
import ScrollX from 'components/ScrollX';
import IconButton from 'components/@extended/IconButton';
import ExpandingPolicyDetail from 'sections/tables/react-table/ExpandingPolicyDetail';



import {
  EmptyTable,
  Filter,
  HeaderSort,
  RowSelection,
  TablePagination,
  RowEditable,
  DraggableColumnHeader,
} from 'components/third-party/react-table';

//assets
import { ArrowDown2, ArrowRight2, CloseCircle, Command, Edit2 } from 'iconsax-react';
import { useTranslation } from 'react-i18next';
import ButtonComponent from 'components/ButtonComponent';
import { CheckRounded, Edit, Remove, RemoveCircle, RoundedCorner, Square, SquareRounded } from '@mui/icons-material';
import InputComponent from 'components/InputComponent';
import CheckBox from '@mui/icons-material/CheckBox';

export const fuzzyFilter = (row, columnId, value, addMeta) => {
  const itemRank = rankItem(row.getValue(columnId), value);
  addMeta(itemRank);
  return itemRank.passed;
};

export const fuzzySort = (rowA, rowB, columnId) => {
  let dir = 0;
  if (rowA.columnFiltersMeta[columnId]) {
    dir = compareItems(rowA.columnFiltersMeta[columnId], rowB.columnFiltersMeta[columnId]);
  }
  return dir === 0 ? sortingFns.alphanumeric(rowA, rowB, columnId) : dir;
};

// ==============================|| REACT TABLE - EDIT ACTION ||============================== //

function EditAction({ row, table }) {
  const meta = table?.options?.meta;
  const setSelectedRow = (e) => {
    meta?.setSelectedRow((old) => ({
      ...old,
      [row.id]: !old[row.id]
    }));
    meta?.revertData(row.index, e?.currentTarget.name === 'cancel');
  };

  const { t } = useTranslation()

  return (
    <Stack direction="row" spacing={1} alignItems="center">
      {meta?.selectedRow[row.id] && (
        <Tooltip title={t('policies.cancel')}>
          <IconButton color="error" name="cancel" onClick={setSelectedRow}>
            <CloseCircle size="15" variant="Outline" />
          </IconButton>
        </Tooltip>
      )}
      <Tooltip title={meta?.selectedRow[row.id] ? t('policies.save') : t('policies.edit')}>
        <IconButton color={meta?.selectedRow[row.id] ? 'success' : 'primary'} onClick={setSelectedRow}>
          {meta?.selectedRow[row.id] ? <Send size="15" variant="Bold" /> : <Edit2 variant="Outline" />}
        </IconButton>
      </Tooltip>
    </Stack>
  );
}

// ==============================|| REACT TABLE ||============================== //

function ReactTable({ defaultColumns, data, onRefresh, onFiltered, fullData }) {
  const theme = useTheme();
  const { t } = useTranslation();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('sm'));
  const [rowSelection, setRowSelection] = useState({});
  const [columnFilters, setColumnFilters] = useState([]);
  const [globalFilter, setGlobalFilter] = useState('');
  const [sorting, setSorting] = useState([]);
  const [grouping, setGrouping] = useState([]);
  const [columns] = useState(() => [...defaultColumns]);
  const [columnOrder, setColumnOrder] = useState(columns.map((column) => column.id));
  const [columnVisibility, setColumnVisibility] = useState({});
  const [selectedPolicy, setSelectedPolicy] = useState(null);

  const table = useReactTable({
    data,
    columns,
    defaultColumn: { cell: RowEditable },
    state: {
      rowSelection,
      columnFilters,
      globalFilter,
      sorting,
      grouping,
      columnOrder,
      columnVisibility
    },
    enableRowSelection: true,
    onRowSelectionChange: setRowSelection,
    onSortingChange: setSorting,
    onGroupingChange: setGrouping,
    onColumnFiltersChange: setColumnFilters,
    onGlobalFilterChange: setGlobalFilter,
    onColumnOrderChange: setColumnOrder,
    onColumnVisibilityChange: setColumnVisibility,
    getRowCanExpand: () => true,
    getExpandedRowModel: getExpandedRowModel(),
    getGroupedRowModel: getGroupedRowModel(),
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
    getFacetedUniqueValues: getFacetedUniqueValues(),
    getFacetedMinMaxValues: getFacetedMinMaxValues(),
    globalFilterFn: fuzzyFilter,
    // getRowId: (row) => row.id.toString(),
    debugTable: true,
    debugHeaders: true,
    debugColumns: true
  });

  useEffect(() => setColumnVisibility({ id: false, role: false, contact: false, country: false, progress: false }), []);

  const backColor = alpha(theme.palette.primary.lighter, 0.1);

  let headers = [];
  table.getVisibleLeafColumns().map(
    (columns) =>
      columns.columnDef.accessorKey &&
      headers.push({
        label: typeof columns.columnDef.header === 'string' ? columns.columnDef.header : '#',
        key: columns.columnDef.accessorKey
      })
  );

  return (
    <MainCard content={false}>
      <ScrollX>
        <RowSelection selected={Object.keys(rowSelection).length} />
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => {
                    if (header.column.columnDef.meta !== undefined && header.column.getCanSort()) {
                      Object.assign(header.column.columnDef.meta, {
                        className: header.column.columnDef.meta.className + ' cursor-pointer prevent-select'
                      });
                    }

                    return (
                      <DraggableColumnHeader key={header.id} header={header} table={table}>
                        <>
                          {header.isPlaceholder ? null : (
                            <Stack direction="row" spacing={1} alignItems="center">
                              {header.column.getCanGroup() && (
                                <IconButton
                                  color={header.column.getIsGrouped() ? 'error' : 'primary'}
                                  onClick={header.column.getToggleGroupingHandler()}
                                  size="small"
                                  sx={{ p: 0, width: 24, height: 24, fontSize: '1rem', mr: 0.75 }}
                                >
                                  {header.column.getIsGrouped() ? (
                                    <Command size="32" color="#FF8A65" variant="Bold" />
                                  ) : (
                                    <TableDocument size="32" variant="Outline" />
                                  )}
                                </IconButton>
                              )}
                              <Box>{flexRender(header.column.columnDef.header, header.getContext())}</Box>
                              {header.column.getCanSort() && <HeaderSort column={header.column} sort />}
                            </Stack>
                          )}
                        </>
                      </DraggableColumnHeader>
                    );
                  })}
                </TableRow>
              ))}
            </TableHead>
            <TableHead>
              {table.getHeaderGroups().map((headerGroup) => (
                <TableRow key={headerGroup.id}>
                  {headerGroup.headers.map((header) => (
                    <TableCell key={header.id} {...header.column.columnDef.meta}>
                      {header.column.getCanFilter() && <Filter column={header.column} table={table} />}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableHead>
            <TableBody>
              {table.getRowModel().rows.length > 0 ? (
                table.getRowModel().rows.map((row) => (
                  <Fragment key={row.id}>
                    <TableRow row={row} reorderRow={() => { }}>
                      <>
                        {row.getVisibleCells().map((cell) => {
                          let bgcolor = 'background.paper';
                          if (cell.getIsGrouped()) bgcolor = 'primary.lighter';
                          if (cell.getIsAggregated()) bgcolor = 'warning.lighter';
                          if (cell.getIsPlaceholder()) bgcolor = 'error.lighter';

                          if (cell.column.columnDef.meta !== undefined && cell.column.getCanSort()) {
                            Object.assign(cell.column.columnDef.meta, {
                              style: { backgroundColor: bgcolor }
                            });
                          }

                          return (
                            <TableCell
                              key={cell.id}
                              {...cell.column.columnDef.meta}
                              sx={{ bgcolor }}
                              {...(cell.getIsGrouped() &&
                                cell.column.columnDef.meta === undefined && {
                                style: { backgroundColor: bgcolor }
                              })}
                            >
                              {cell.getIsGrouped() ? (
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                  <IconButton
                                    color="secondary"
                                    onClick={row.getToggleExpandedHandler()}
                                    size="small"
                                    sx={{ p: 0, width: 24, height: 24 }}
                                  >
                                    {row.getIsExpanded() ? (
                                      <ArrowDown2 size="32" variant="Outline" />
                                    ) : (
                                      <ArrowRight2 size="32" variant="Outline" />
                                    )}
                                  </IconButton>
                                  <Box>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Box> <Box>({row.subRows.length})</Box>
                                </Stack>
                              ) : cell.getIsAggregated() ? (
                                flexRender(cell.column.columnDef.aggregatedCell ?? cell.column.columnDef.cell, cell.getContext())
                              ) : cell.getIsPlaceholder() ? null : (
                                flexRender(cell.column.columnDef.cell, cell.getContext())
                              )}
                            </TableCell>
                          );
                        })}
                      </>
                    </TableRow>
                    {row.getIsExpanded() && !row.getIsGrouped() && (
                      <TableRow onClick={() => setSelectedPolicy(row.original)} sx={{ bgcolor: backColor, '&:hover': { bgcolor: `${backColor} !important` } }}>
                        <TableCell colSpan={row.getVisibleCells().length + 2}>
                          <ExpandingPolicyDetail data={selectedPolicy || row.original} />
                        </TableCell>
                      </TableRow>
                    )}
                  </Fragment>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={table.getAllColumns().length}>
                    <EmptyTable msg={t('policies.no_data')} />
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
            <TableFooter>
              {table.getFooterGroups().map((footerGroup) => (
                <TableRow key={footerGroup.id}>
                  {footerGroup.headers.map((footer) => (
                    <TableCell key={footer.id} {...footer.column.columnDef.meta}>
                      {footer.isPlaceholder ? null : flexRender(footer.column.columnDef.header, footer.getContext())}
                    </TableCell>
                  ))}
                </TableRow>
              ))}
            </TableFooter>
          </Table>
        </TableContainer>
        <Divider />
        <Box sx={{ p: 2 }}>
          <TablePagination
            {...{
              setPageSize: table.setPageSize,
              setPageIndex: table.setPageIndex,
              getState: table.getState,
              getPageCount: table.getPageCount
            }}
          />
        </Box>
      </ScrollX>
    </MainCard>
  );
}

// ==============================|| REACT TABLE - UMBRELLA ||============================== //

export default function AccountManagement() {
  const { t } = useTranslation();

  const deleteAccount = (row) => {
    let con = window.confirm(`Êtes-vous sûr de vouloir supprimer le compte ${row.original.username}`)
    if(!con) return;
    AccountService.deleteAccount(row.original.username)
      .then((response) => {
        getAccountData();
      })
      .catch((err) => {
        console.log('account list error:', err);
      });
    }


    const columns = useMemo(
      () => [
        {
          id: 'username',
          title: "Nom d'utilisateur",
          header: "Nom d'utilisateur",
          accessorKey: 'username',
          dataType: 'text',
          enableGrouping: true,
        },
        {
          id: 'name',
          header: "Nom & Prénom",
          footer: "Nom & Prénom",
          accessorKey: 'name',
          dataType: 'text',
          enableGrouping: true
        },
        {
          id: 'is_super_admin',
          header: 'Super Admin',
          footer: 'Super Admin',
          accessorKey: 'is_super_admin',
          dataType: 'text',
          enableGrouping: true,
          cell: ({ row }) => {
            if (row.original.is_super_admin) return <CheckRounded />
          }
        },
        {
          id: 'action',
          enableGrouping: false,
          header: () => null,
          width: 100,
          cell: ({ row }) => {
            return <>
              <IconButton
                edge="end"
                aria-label="download"
                target="_blank" // Open the link in a new tab
                rel="noopener noreferrer" // Improve security when opening in a new tab
                onClick={() => editAccount(row.original)}
              >
                <Edit />
              </IconButton>
              &nbsp;&nbsp;
              <IconButton
                edge="end"
                aria-label="download"
                target="_blank" // Open the link in a new tab
                rel="noopener noreferrer" // Improve security when opening in a new tab
                onClick={() => deleteAccount(row)}
              >
                <RemoveCircle />
              </IconButton>
            </>;
          }
        },
      ],
      [t]
    );

    const editAccount = (account) => {
      setEditmode(true)
      setUsername(account.username)
      setName(account.name)
      setPassword(account.password)
      setPassword2(account.password)
      setIsSuperAdmin(account.is_super_admin)
    }

    const submitform = () => {

      let account = { name, username, password, is_super_admin: isSuperAdmin }
      if (editmode) {
        AccountService.updateAccount(account)
          .then((response) => {
            getAccountData()
            resetForm();
            getAccountData();
          })
          .catch((err) => {
            console.log('account list error:', err);
          });
      }
      else {
        AccountService.createNewAccount(account)
          .then((response) => {
            getAccountData()
            resetForm();
            getAccountData();
          })
          .catch((err) => {
            console.log('account list error:', err);
          });
      }

    }

    const resetForm = () => {
      setEditmode(false)
      setName('')
      setUsername('')
      setPassword('')
      setPassword2('')
      setIsSuperAdmin(false)
    }

    const [accountData, setAccountData] = useState([]);
    const [accountDataFull, setaccountDataFull] = useState([]);

    const [name, setName] = useState('')
    const [username, setUsername] = useState('')
    const [editmode, setEditmode] = useState(false)
    const [password, setPassword] = useState('')
    const [password2, setPassword2] = useState('')
    const [isSuperAdmin, setIsSuperAdmin] = useState(false)

    useEffect(() => {
      if (accountData.length === 0) {
        getAccountData();
      }
    }, [accountData]);

    function getAccountData() {
      AccountService.getAccountList()
        .then((response) => {
          if (response) {
            setAccountData(response);
            setaccountDataFull(response);
          }
        })
        .catch((err) => {
          console.log('account list error:', err);
        });
    }

    const onRefresh = () => {
      getAccountData();
    };

    return (
      <DndProvider backend={isMobile ? TouchBackend : HTML5Backend}>
        <div style={{ maxWidth: 800, padding: 20, border: '1px solid #E8EBEE', marginBottom: 20, borderRadius: 20 }}>
        <h3>Informations sur l'administrateur</h3>
<h5>Veuillez remplir les informations suivantes pour soumettre le formulaire</h5>

          <Grid container spacing={{ xs: 5, md: 2 }}>
            <Grid item xs={12} sm={12}>
              <InputComponent
                readOnly={editmode}
                disabled={editmode}
                label="Nom d'utilisateur"
                customMargin={true}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <InputComponent
                label="Nom & prénom"
                customMargin={true}
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <InputComponent
                type={"password"}
                label="Mot de passe"
                customMargin={true}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <InputComponent
                type={"password"}
                label="Réécrire le mot de passe"
                customMargin={true}
                value={password2}
                onChange={(e) => setPassword2(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={12}>
              <Checkbox checked={isSuperAdmin} color="success" onChange={(e) => setIsSuperAdmin(!isSuperAdmin)} />
              Est super admin
            </Grid>
            <Grid item xs={12} sm={12}>
              <ButtonComponent onClick={() => submitform()} width={200} label={editmode ? "Mettre à jour" : "Créer"}></ButtonComponent>&nbsp;&nbsp;&nbsp;
              <ButtonComponent onClick={() => resetForm()} width={200} label={"Annuler"}></ButtonComponent>
            </Grid>
          </Grid>
        </div>
        <div style={{ padding: 20, border: '1px solid #E8EBEE', marginBottom: 20, borderRadius: 20 }}>
          <h3>Administrateurs dans le système</h3>
          <ReactTable {...{ data: accountData, defaultColumns: columns, onRefresh, fullData: accountDataFull }} />
        </div>
      </DndProvider>
    );
  }

  EditAction.propTypes = { row: PropTypes.object, table: PropTypes.object };

  ReactTable.propTypes = { defaultColumns: PropTypes.array, data: PropTypes.array, onRefresh: PropTypes.func };
