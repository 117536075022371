import serviceLinks from "../common/Links.json"
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";
import Constants from "../common/Constants";
const headers = new Headers();

class PoliciesService {
    static getMyPoliciesList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = serviceLinks.getMyPoliciesList;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static getAllPoliciesList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = serviceLinks["Policies"]["all"].url;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }


    static getPolicyDetailByPolicyId(policyId) {
        if(policyId){
            const url = serviceLinks.getPolicyDetailByPolicyId + policyId;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static getEventsByPolicyId(clientId, policyId) {
        if (clientId && policyId) {
            const url = `${serviceLinks.Policies.getEventsByPolicyId.url}/${clientId}/${policyId}`;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs)
                    .then(data => {
                        resolve(data);
                    })
                    .catch(err => {
                        reject(err);
                    });
            });
        }
    }

}

export default PoliciesService;