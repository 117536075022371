import React, { useState } from 'react';
import {
  Grid,
  Box,
  Stack,
  useMediaQuery,
  List,
  ListItem,
  ListItemText,
  Drawer,
  IconButton,
  ListItemButton
} from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import LogoMain from 'components/logo/LogoMain';
import LanguageSwitcher from 'components/LanguageSwitcher';
import ButtonComponent from 'components/ButtonComponent';
import { useTranslation } from 'react-i18next';
import Lottie from 'lottie-react';
import homepage1 from 'assets/homePageAssets/homepage1.json';
import homepage2 from 'assets/homePageAssets/homepage2.json';
import Footer from 'layouts/Dashboard/Footer';
import { useNavigate } from 'react-router-dom';

const HomepageLayout = ({ children }) => {
  const {t} = useTranslation();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down('sm'));
  const navigate = useNavigate()

  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setDrawerOpen(open);
  };

  const handleNavigateLogin = () => navigate('/login')
  const handleNavigateSignUp = () => navigate('/sign-up')

  const drawerContent = (
    <Box
      sx={{width: 250}}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItemButton onClick={handleNavigateSignUp}>
          <ListItemText primary={t('homePage.requestQuote')}/>
        </ListItemButton>
       
  
        <ListItemButton onClick={handleNavigateLogin}>
          <ListItemText primary={t('homePage.login')}/>
        </ListItemButton>
        
      </List>
    </Box>
  );


  return (
    <Box
      sx={{
        textAlign: 'center',
        display: 'flex',
        flexDirection: 'column',
        height: '100vh',
        width: '100%',
        overflow: 'auto',
        position: 'relative',
        overflowX: 'hidden',
      }}
    >
      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 120,
            left: -20,
            zIndex: 1,
            width: '40%',
            height: '60%',
            overflow: 'hidden',
            '@media (max-width: 1400px)': {
              top: 170,
              width: '35%',
              height: '50%',
            },
            '@media (max-width: 1220px)': {
              top: 120,
              width: '30%',
              height: '55%',
            },
            '@media (max-width: 1140px)': {
              top: 100,
              width: '27%',
              height: '52%',
            },
          }}
        >
          <Lottie
            animationData={homepage1}
            loop={true}
            style={{width: '100%', height: '100%'}}
          />
        </Box>
      )}

      {!isMobile && (
        <Box
          sx={{
            position: 'absolute',
            top: 300,
            right: 0,
            zIndex: 1,
            width: '36%',
            height: '60%',
            overflow: 'hidden',
            '@media (max-width: 1400px)': {
              width: '31%',
              height: '50%',
            },
            '@media (max-width: 1200px)': {
              width: '31%',
              height: '55%',
            },
            '@media (max-width: 1020px)': {
              width: '31%',
              height: '52%',
            },
          }}
        >
          <Lottie
            animationData={homepage2}
            loop={true}
            style={{width: '100%', height: '100%'}}
          />
        </Box>
      )}


      <Stack
        direction="row"
        alignItems="center"
        justifyContent="space-between"
        position="sticky"
        top="0"
        sx={{
          px: 3,
          height: '100px',
          borderBottom: '1px solid lightgray',
          zIndex: 9999, backgroundColor: 'white',
          boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
          padding: '7px'
        }}
      >
        <Box sx={{paddingLeft: '15px'}}>
          <LogoMain
            height="50px"
            width="150px"

          />
        </Box>

        <Box sx={{display: {xs: 'block', md: 'none'}}}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="menu"
            onClick={() => setDrawerOpen(!drawerOpen)}
          >
            <MenuIcon/>
          </IconButton>
        </Box>

        <Stack
          direction="row"
          alignItems="center"
          spacing={2}
          sx={{display: {xs: 'none', md: 'flex'}}}
          paddingRight="15px"
        >
          <ButtonComponent
            label={t('homePage.requestQuote')}
            width="150px"
            height="40px"
            onClick={handleNavigateSignUp}
          />


     <ButtonComponent
            label={t('homePage.login')}
            width="150px"
            height="40px"
            onClick={handleNavigateLogin}
          />
          
        </Stack>
      </Stack>

      <Drawer
        anchor="left"
        open={drawerOpen}
        onClose={toggleDrawer(false)}
        sx={{ zIndex: 10000 }}
      >
        {drawerContent}
      </Drawer>

      <Grid
        container
        style={{
          flex: 0.9,
          textAlign: '-webkit-center',
          width: '100%',
          margin: '0 auto',
          zIndex: 2,
        }}
      >
        <div style={{width: '100%', paddingTop: 50}}>{children}</div>
      </Grid>
      <Footer isMobile={isMobile}/>
    </Box>
  );
};

export default HomepageLayout;
