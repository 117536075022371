import React, { useEffect, useState } from 'react';
import InputComponent from 'components/InputComponent';
import { useTranslation } from 'react-i18next';
import Constants, { COMPLAINT } from 'common/Constants';
import { uploadDocuments } from "services/DocumentsService";

import {
  Alert,
  Autocomplete,
  Box,
  Button,
  FormControl,
  Grid,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Stack,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  TextField,
  Typography,
  FormHelperText,
} from '@mui/material';

import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import ComplaintService from 'services/ComplaintService';
import axiosServices from 'utils/axios';
import { Ethereum } from 'iconsax-react';
import dateFormat from 'dateformat';
import * as Yup from 'yup';

const CreateComplaintFormLayout = ({ onClose, onRefresh }) => {
  const { t } = useTranslation();
  const clientData = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));

  const [formState, setFormState] = useState({
    complaintType: null,
    selectedPolicy: null,
    selectedClaim: null,
    complaintDescription: '',
    firstName: clientData.first_name,
    lastName: clientData.last_name,
    phoneNumber: clientData.phone_number,
    emailContact: clientData.email,
    complaintDate: '', // Add new fields
    receiptDate: '',
    financialAgreementDate: '',
    financialCompensationAmount: 0,
    assignedStaffId: '86246b98-632f-4c50-aba6-ce42132c6599',
  });

  const [activeStep, setActiveStep] = useState(0);
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [policies, setPolicies] = useState([]);
  const [claims, setClaims] = useState([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [errors, setErrors] = useState({});
  const [localFiles, setLocalFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("Loading");
  const [complaintError, setComplaintError] = useState(false);

  const handleAddFiles = (newFiles) => {
    setLocalFiles((prev) => [...prev, ...newFiles]);
};

const handleRemoveFile = (fileName) => {
    setLocalFiles((prev) => prev.filter((file) => file.name !== fileName)); 
};

const complaintTypes = [
  { code: 1, name: "Contrat", type: 'other' },
  { code: 2, name: "Sinistre", type: 'other' },
  { code: 3, name: "Paiement", type: 'other' },
  { code: 4, name: "Connexion", type: 'other' },
  { code: 5, name: "Relation client", type: 'other' },
  { code: 6, name: "Autre", type: 'other' },
];

  const renderComplaintTypeMenuItems = () => {
    return complaintTypes.map(type => (
        <MenuItem key={type.code} value={type}>
          {type.name}
        </MenuItem>
    ));
  };

  const validateFields = () => {
    const errors = {};
    if (!formState.complaintType) {
      errors.complaintType = 'Complaint type is required.';
    }
    if (!formState.complaintDescription.trim()) {
      errors.complaintDescription = 'Complaint description is required.';
    }
    return errors;
  };

  

  const steps = [
    { label: t('complaints.review_information'), description: t('complaints.review_information_desc') },
    { label: t('complaints.fill_complaint'), description: t('complaints.fill_complaint_desc') },
    { label: t('complaints.upload_evidence'), description: t('complaints.upload_evidence_desc') },
  ];

  const handleUploadFiles = ({ data, isError = false }) => {
    setFileRecords(data);
    setFilesError(isError);
  };

  

  const handleBack = () => setActiveStep((prevStep) => prevStep - 1);

  const handleChange = (field, value) => {
    setFormState((prevState) => ({
      ...prevState,
      [field]: value,
    }));
  };

  const fetchPolicies = async () => {
    try {
      const response = await axiosServices.get('/policies/my-policies');
      setPolicies(response.data || []);
    } catch (err) {
      console.error('Error fetching policies:', err);
    }
  };

  const fetchClaims = async () => {
    try {
      const response = await axiosServices.get('/claims/my-claims');
      setClaims(response.data || []);
    } catch (err) {
      console.error('Error fetching claims:', err);
    }
  };

  const prepareCreateComplaintData = (uploadedFiles) => {
    const fileList = uploadedFiles.map((item) => item.file_id);
    return {
      complaint_type_id: formState.complaintType, // Numeric code from dropdown
      portfolio_id: formState.selectedPolicy?.id || null, // Optional
      assigned_staff_id: formState.assignedStaffId || '86246b98-632f-4c50-aba6-ce42132c6599', // Ensure it's included, set to null if undefined
      files: fileList, // Uploaded file IDs
      description: formState.complaintDescription,
      email_contact: formState.emailContact,
      phone_number: formState.phoneNumber,
      policy_id: formState.selectedPolicy?.id || null, // Only include if selected
      claim_id: formState.selectedClaim?.id || null, // Only include if selected
    };
  };

  const handleQuit = () => {
    onClose();
  };

  const createNewComplaint = async () => {
    setLoading(true);
    let interval;
    try {
        let dots = "";
        interval = setInterval(() => {
            dots = dots.length < 3 ? dots + "." : "";
            setLoadingText(`loading${dots}`);
        }, 500);
  
        const uploadResponse = await uploadDocuments(localFiles);
        const uploadedFileRecords = uploadResponse.data.records;
      const data = prepareCreateComplaintData(uploadedFileRecords);

      const headers = {
        business_partner_id: Constants.BUSINESS_PARTNER_ID, // Replace with actual value
        client_id: Constants.CLIENT_ID, // Replace with actual value
      };

      const response = await ComplaintService.createNewComplaint(data, headers);
      if (response) {
        setActiveStep((prevStep) => prevStep + 1);
        onRefresh();
        try {
          const { id: complaintId, client_id: clientId } = response;
          if (!complaintId || !clientId) {
            throw new Error("Missing complaintId or clientId in server response.");
          }

          const emailResponse = await ComplaintService.sendEmailForComplaint(complaintId,clientId);
        } catch (emailError) {
          console.error("Error sending email notification:", emailError);
        }
      } else {
        throw new Error("Complaint creation failed: No response from server");
      }
    } catch (err) {
      console.error('Error creating complaint:', err);
    }
    finally {
      clearInterval(interval);
      setLoading(false);
      setLoadingText("Loading");
  }
  };

  const handleNext = () => {
    setComplaintError(false)
    if (activeStep === steps.length - 1) {
        if (localFiles.length === 0) {
            setFilesError(true);
            return;
        }
        createNewComplaint();
    }else {
      if (activeStep === 1  && !isFormValid()) {
        setComplaintError(true);
        return;
      }

      setActiveStep(activeStep + 1);
    }
    
  };

  const isFormValid = () => {
    const errors = validateFields();
    setErrors(errors); // Update errors state to reflect validation issues
    return Object.keys(errors).length === 0;
  };

  const renderInformation = () => (
      <List>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputComponent label={t('complaints.first_name')} value={formState.firstName} readOnly />
            </Grid>
            <Grid item xs={6}>
              <InputComponent label={t('complaints.last_name')} value={formState.lastName} readOnly />
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <InputComponent
                  label={t('complaints.phone_number')}
                  value={formState.phoneNumber}
                  onChange={(e) => handleChange('phoneNumber', e.target.value)}
              />
            </Grid>
            <Grid item xs={6}>
              <InputComponent
                  label={t('complaints.email_contact')}
                  value={formState.emailContact}
                  onChange={(e) => handleChange('emailContact', e.target.value)}
              />
            </Grid>
          </Grid>
        </ListItem>
      </List>
  );

  const renderComplaintForm = () => (
      <List>
        {complaintError && <Alert style={{ marginBottom: 10 }} color="error" icon={<Ethereum variant="Bold" />}>
            {t('claims.errors.fillAllData')}
          </Alert>}
        <ListItem>
          <FormControl fullWidth error={!!errors.complaintType && hasSubmitted}>
            <InputLabel>{t('complaints.complaint_type')}</InputLabel>
            <Select
                value={formState.complaintType || ''}
                onChange={(e) => handleChange('complaintType', Number(e.target.value))}
            >
              {complaintTypes.map((type) => (
                  <MenuItem key={type.code} value={type.code}>
                    {type.name}
                  </MenuItem>
              ))}
            </Select>
          </FormControl>
        </ListItem>
        {formState.complaintType?.type === 'policy' && (
            <ListItem>
              <Autocomplete
                  options={(policies || []).map((policy) => ({
                    id: policy.id,
                    label: `${policy.amendment_number} (${dateFormat(policy.inception_date, 'dd-mm-yyyy')})`,
                  }))}
                  renderInput={(params) => <TextField {...params} placeholder={t('complaints.select_policy')} />}
                  value={formState.selectedPolicy}
                  onChange={(event, newValue) => handleChange('selectedPolicy', newValue)}
              />
            </ListItem>
        )}
        {formState.complaintType?.type === 'claim' && (
            <ListItem>
              <Autocomplete
                  options={(claims || []).map((claim) => ({
                    id: claim.id,
                    label: `${claim.claim_reference_number} - ${claim.description}`,
                  }))}
                  renderInput={(params) => <TextField {...params} placeholder={t('complaints.select_claim')} />}
                  value={formState.selectedClaim}
                  onChange={(event, newValue) => handleChange('selectedClaim', newValue)}
              />
            </ListItem>
        )}
        
        <ListItem>
          <TextField
              fullWidth
              multiline
              rows={4}
              label={t('complaints.complaint_description')}
              value={formState.complaintDescription}
              onChange={(e) => handleChange('complaintDescription', e.target.value)}
          />
        </ListItem>
      </List>
  );

  const renderUpload = () => (
      <>
        {filesError && <Alert severity="error">{t('claim.evidence')}</Alert>}
        <MultiFileUpload onUpload={handleUploadFiles} onRemoveFile={handleRemoveFile}  onAddFile={handleAddFiles} />
      </>
  );

  return (
      <Box width={600}>
        
            <Stepper activeStep={activeStep} orientation="vertical">
              {steps.map((step, index) => (
                  <Step key={index}>
                    <StepLabel>{step.label}</StepLabel>
                    <StepContent>
                      {index === 0 && renderInformation()}
                      {index === 1 && renderComplaintForm()}
                      {index === 2 && renderUpload()}
                      <Box sx={{ mb: 2 }}>
                      <div>
                      <Button
  variant="contained"
  onClick={handleNext}
  sx={{ mt: 1, mr: 1 }}
  color={index === steps.length - 1 ? 'primary' : 'secondary'}
  disabled={loading} 
>
  {loading && index === steps.length - 1
    ? loadingText
    : index === steps.length - 1
    ? "Déclarer un avis"
    : t('claims.buttons.continueNextStep')}
</Button>
                        <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mt: 1, mr: 1 }}>
                        {t('claims.buttons.back')}
                        </Button>
                        </div>
                      </Box>
                    </StepContent>
                  </Step>))}
              
            </Stepper>
            {activeStep === steps.length && (
              <Box sx={{ pt: 2 }}>
                <Typography sx={{ color: 'success.main' }}>Votre avis a été soumis - Nous traiterons votre avis dans les 48 heures.</Typography>
                <Button
                    variant="outlined"
                    color="error"
                    onClick={handleQuit} 
                    sx={{ mt: 2 }}
                  >
                    Quitter
                  </Button>
              </Box>
            )}
      </Box>
  );
};

export default CreateComplaintFormLayout;
