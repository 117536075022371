import axiosServices, { fetcherPost } from 'utils/axios';
import serviceLinks from 'common/Links.json';
import { fetcher, fetcherPut } from 'utils/axios';
import Headers from 'common/types/Headers';

const headers = new Headers();

class ClientService {
  static getClientIdByEmail(email) {
    if (!email) {
      return Promise.reject(new Error('Email is required to fetch client ID.'));
    }

    const url = `/clients/get-by-email/${encodeURIComponent(email)}`;
    const headers = {
      business_partner_id: 'a5f5c9f4-d1d8-4c6d-9cde-9e8e38dbb6bb',
    };

    return new Promise((resolve, reject) => {
      axiosServices
        .get(url, { headers })
        .then((response) => {
          resolve(response.data.id);
        })
        .catch((error) => {
          console.error('Error fetching client ID by email:', error);
          reject(error);
        });
    });
  }

  static async getClientList() {
    const url = serviceLinks['Clients']['get-all'].url;
    try {
      return await fetcher([url, { method: 'GET', headers: headers.GetHeaders() }]);
    } catch (err) {
      throw err;
    }
  }

  static async createNewClient(client) {
    const url = serviceLinks['Clients']['create'].url;
    try {
      return await fetcherPost([url, { headers: headers.GetHeaders(), ...client }]);
    } catch (err) {
      throw err;
    }
  }

  static async updateClient(client) {
    const url = serviceLinks['Clients']['update-profile'].url.replace('{id}', client.id);
    try {
      return await fetcherPut([url, client, { headers: headers.GetHeaders() }]);
    } catch (err) {
      throw err;
    }
  }
}

export default ClientService;
