import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import InputComponent from '../../../components/InputComponent';
import Slider from '@mui/material/Slider';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  List,
  ListItem,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import MainCard from 'components/MainCard';
import { TickCircle } from 'iconsax-react';
import axiosServices, { fetcher, fetcherPost } from 'utils/axios';
import { RemoveCircle } from '@mui/icons-material';
import { format as currencyFormatter } from 'currency-formatter';
import IconButton from 'components/@extended/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import ClientService from '../../../services/ClientService';
import Constants from '../../../common/Constants';
import { loadStripe } from '@stripe/stripe-js';

const stripePromise = loadStripe(
  'pk_test_51QixKiCI89TBnp7J4wpJRcw708LshGES6WDJcidkOVd6PZaPF0uUVz1CwAH4uNiaUqth3YIKHKLVTru5aMePNULm00yAsPUn8g'
);

const SliderContainer = styled('div')({
  width: '70%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  textAlign: 'center',
  marginTop: '10px',
  color: '#EB3D9F',
  fontFamily: 'Roboto, sans-serif',
  fontSize: '17px',
  lineHeight: '18px',
  letterSpacing: '0.5px',
  marginLeft: '15%',
  '@media (max-width: 1370px)': {
    width: '80%',
    marginLeft: '10%',
  },
  '@media (max-width: 955px)': {
    width: '90%',
    marginLeft: '5%',
    marginTop: '0px',
  },
});

const price = {
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: 1,
};

const Step9 = ({ nextStep, prevStep, userType }) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState([]);
  const [risks, setRisks] = useState([]);
  const [pricingTable, setPricingTable] = useState([]);
  const productId = '19edb627-9ddb-4df4-bc60-8ac4ac8c2e37'; // TODO: replace with actual product ID
  const [maximumPrice, setMaximumPrice] = useState(500);
  const [open, setOpen] = useState(false);
  const [explanation, setExplanation] = useState();
  const client = JSON.parse(localStorage.getItem(Constants.CLIENT_OBJECT));
  const [clientID, setClientID] = useState([]);
  const [quotation, setQuotation] = useState({});

  useEffect(() => {
    getRiskObjects();
    getRiskPriceTable();
    fetchClientId();
  }, []);

  const generateQuotation = () => {
    return {
      product_id: productId,
      maximum_amount_of_compensation_per_claim: maximumPrice,
      number_of_claim_per_year: 3,
      risk_ids: selected,
      total_amount: priceInfo.monthly,
      price_combo_id: priceInfo.price_combo_id,
    };
  };

  const fetchClientId = async () => {
    try {
      const clientId = await ClientService.getClientIdByEmail(client.email);
      setClientID(clientId); // Update the client object with fetched ID
      console.log('je suis ici!: ' + clientId);
    } catch (error) {
      console.error('Error fetching client ID:', error.message);
    }
  };

  const createQuotation = async (quote) => {
    const request = await fetcherPost([`/quotes/`, quote]);
    console.log(request.quote_code);
  };

  const sendEmailWithAttachments = async () => {
    try {
      if (!quotation) {
        console.error('Quotation is not available');
        return;
      }

      const emailPayload = {
        email: quotation.client.email,
        subject: `Quotation Documents for ${quotation.quote_code}`,
        message: 'Please find the attached quotation documents.',
      };

      await axiosServices.post('/quotes/send-email-with-pdfs', emailPayload);

      alert('Email sent successfully!');
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email. Please try again.');
    }
  };

  const getRiskObjects = async () => {
    let request = await fetcher(`/risks/list-by-product-id/${productId}`);
    setRisks(request);
    request.map((risk) => handleSelect(risk.id));
  };

  const getRiskPriceTable = async () => {
    let request = await fetcher('/risk_price_combo');
    setPricingTable(request);
  };

  const onSliderChange = (value) => {
    setMaximumPrice(value);
  };

  const calculatePrice = useCallback(
    (amount, selectedRisks) => {
      const allRisks = risks.map((risk) => risk.id);
      const defaultPrices = {};

      // Populate default prices for each individual risk
      allRisks.forEach((risk) => {
        const riskEntry = pricingTable.find(
          (item) => item.maximum_amount === amount.toString() && item.risks.length === 1 && item.risks.includes(risk)
        );

        if (riskEntry) {
          defaultPrices[risk] = riskEntry.price;
        } else {
          defaultPrices[risk] = 'N/A';
        }
      });

      const defaultObject = {
        price_combo_id: undefined,
        risks: defaultPrices,
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

      // If no risks are selected, return default prices and total = 0
      if (!selectedRisks || selectedRisks.length === 0) {
        return defaultObject;
      }

      // Find the pricing entry for the selected risks
      const selectedEntry = pricingTable.find(
        (item) =>
          item.maximum_amount === amount.toString() &&
          item.risks.length === selectedRisks.length &&
          item.risks.every((risk) => selectedRisks.includes(risk))
      );

      if (!selectedEntry) {
        return defaultObject;
      }

      // Get the price for the selected risks
      const monthlyCost = selectedEntry.price;
      const annualCost = monthlyCost * 12;

      // Calculate additional prices for unselected risks
      const additionalPrices = {};
      const unselectedRisks = allRisks.filter((risk) => !selectedRisks.includes(risk));

      unselectedRisks.forEach((unselectedRisk) => {
        const additionalEntry = pricingTable.find(
          (item) =>
            item.maximum_amount === amount.toString() &&
            item.risks.length === selectedRisks.length + 1 &&
            selectedRisks.every((risk) => item.risks.includes(risk)) &&
            item.risks.includes(unselectedRisk)
        );

        if (additionalEntry) {
          additionalPrices[unselectedRisk] = additionalEntry.price - monthlyCost;
        } else {
          additionalPrices[unselectedRisk] = 'N/A';
        }
      });

      return {
        price_combo_id: selectedEntry.id,
        risks: defaultPrices,
        additionalPrices,
        annual: annualCost.toFixed(2),
        monthly: monthlyCost,
      };
    },
    [pricingTable, risks]
  );

  const priceInfo = useMemo(() => {
    if (!risks.length)
      return {
        risks: {},
        additionalPrices: {},
        annual: 0,
        monthly: 0,
      };

    return calculatePrice(maximumPrice, selected);
  }, [risks.length, calculatePrice, maximumPrice, selected]);

  const handleSelect = (id) => {
    setSelected((prevSelected) =>
      prevSelected.includes(id) ? prevSelected.filter((item) => item !== id) : [...prevSelected, id]
    );
  };

  const getQuotation = async (quote) => {
    try {
      console.log('Submitting quote for creation:', quote); // Log the quote being submitted
      const request = await fetcherPost([`/quotes/`, quote]);
      console.log('Response from fetcherPost:', request); // Log fetcherPost response
      if (!request || !request.quote_code) {
        console.error('Missing or invalid quote_code:', request);
        return null;
      }
      const response = await axiosServices.get(`/quotes/get-by-code/${request.quote_code}`);
      console.log('Fetched quotation details:', response.data); // Log fetched quotation
      const fetchedQuotation = response.data;
      setQuotation(fetchedQuotation);
      return fetchedQuotation;
    } catch (error) {
      console.error('Error fetching quotation:', error.message); // Log error
      return null; // Return null explicitly
    }
  };

  const handleCheckout = async () => {
    const confirmation = window.confirm(t('quotation.checkoutConfirmation'));
    if (!confirmation) return;

    try {
      await fetchClientId();
      const clientId = clientID;

      if (!clientId) {
        console.error('Client ID is undefined');
        return;
      }

      const quote = generateQuotation();
      const fetchedQuotation = await getQuotation(quote); // Fetch and use quotation here
      console.log(fetchedQuotation);
      await createQuotation(quote);

      if (!fetchedQuotation || !fetchedQuotation.id) {
        console.error('Quotation or quotation.id is undefined');
        return;
      }

      const { data } = await axiosServices.post(
        '/clients/create-checkout-session',
        {
          quote_id: fetchedQuotation.id,
          amount: priceInfo.monthly * 2, // x2 amount for the first payment
          mode: 'subscription',
        },
        {
          headers: { client_id: clientId },
        }
      );

      const stripe = await stripePromise;
      const { error } = await stripe.redirectToCheckout({ sessionId: data.sessionId });

      if (error) {
        console.error('Stripe checkout error:', error);
        return;
      }

      console.log('Checkout initiated successfully');
    } catch (error) {
      console.error('Error during checkout:', error);
    }
  };

  const CustomSlider = ({ onChange }) => {
    const [sliderValue, setSliderValue] = useState(500);

    const transformValue = (value) => {
      switch (value) {
        case 0:
          return 500;
        case 50:
          return 1000;
        case 100:
          return 2000;
        default:
          return value;
      }
    };

    const reverseTransformValue = (value) => {
      switch (value) {
        case 500:
          return 0;
        case 1000:
          return 50;
        case 2000:
          return 100;
        default:
          return value;
      }
    };

    return (
      <Slider
        value={reverseTransformValue(sliderValue)}
        min={0}
        max={100}
        marks={[
          { value: 0, label: '500 €' },
          { value: 50, label: '1000 €' },
          { value: 100, label: '2000 €' },
        ]}
        step={null}
        onChange={(e, value) => {
          const transformedValue = transformValue(value);
          if (transformedValue !== 1500) {
            onChange(transformedValue);
            setSliderValue(transformedValue);
          }
        }}
      />
    );
  };

  const normalizeText = (text) => text.toLowerCase().replaceAll(' ', '-');
  const handleOpen = (keyword) => {
    setExplanation({ label: keyword, content: t(t(`signup.step9.${normalizeText(keyword)}`)) });
    setOpen(true);
  };
  const handleClose = () => setOpen(false);

  const getRiskPrice = (risk) => {
    if (priceInfo.additionalPrices[risk.id]) {
      return `+ ${currencyFormatter(priceInfo.additionalPrices[risk.id] ?? 0, { code: 'EUR' })}`;
    }
    if (selected.includes(risk.id)) {
      return '-';
    }
    return currencyFormatter(priceInfo.risks[risk.id] ?? 0, { code: 'EUR' });
  };

  return (
    <>
      <List>
        <ListItem sx={{ px: 0 }}>
          <Grid container spacing={3}>
            {risks.map((risk, index) => (
              <Grid item xs={12} md={4} key={index} style={{ textAlign: 'center' }}>
                <MainCard
                  border={true}
                  sx={{
                    px: 0,
                    border: selected.includes(risk.id) ? '2px solid' : 'none',
                    borderColor: selected.includes(risk.id) ? 'primary.main' : 'transparent',
                    height: '100%',
                  }}
                >
                  <Stack alignItems="flex-end">
                    <Tooltip title={risk.risk_label}>
                      <IconButton onClick={() => handleOpen(risk.risk_label)}>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Stack>
                  <Stack direction="row" alignItems="center" justifyContent="center" columnGap={1}>
                    <Typography variant="h3">{risk.risk_label}</Typography>
                  </Stack>
                  <div>
                    <div style={{ marginTop: 30, marginBottom: 30 }}>
                      <Typography variant="h1" sx={price}>
                        {getRiskPrice(risk)}
                      </Typography>
                      <Typography variant="h6" color="text.secondary">
                        {t('signup.step9.month')}
                      </Typography>
                    </div>
                    {selected.includes(risk.id) ? (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{ width: '100%' }}
                        variant="contained"
                        color="primary"
                        startIcon={<TickCircle />}
                      >
                        {t('signup.step9.selected')}
                      </Button>
                    ) : (
                      <Button
                        onClick={() => handleSelect(risk.id)}
                        style={{ width: '100%', border: '1px solid', borderColor: 'grey.500' }}
                        variant="contained"
                        color="grey"
                        startIcon={<RemoveCircle />}
                      >
                        {t('signup.step9.unselected')}
                      </Button>
                    )}
                  </div>
                  <div style={{ textAlign: 'left', marginTop: 50 }}>{risk.description}</div>
                </MainCard>
              </Grid>
            ))}
            {explanation && (
              <Dialog onClose={handleClose} open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }}>{explanation.label}</DialogTitle>
                <IconButton
                  aria-label="close"
                  onClick={handleClose}
                  sx={{
                    position: 'absolute',
                    right: 8,
                    top: 8,
                    color: (theme) => theme.palette.grey[500],
                  }}
                >
                  <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                  <Typography gutterBottom>{explanation.content}</Typography>
                </DialogContent>
              </Dialog>
            )}
          </Grid>
        </ListItem>
        <ListItem>
          <Grid container spacing={3}>
            <Grid item xs={12} md={12}>
              <MainCard border={true} sx={{ height: '100%' }}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={5} style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{t('signup.step9.maxCompensation')}</Typography>
                    <SliderContainer>
                      <CustomSlider onChange={onSliderChange} />
                    </SliderContainer>
                  </Grid>
                  <Grid item xs={12} md={3} style={{ textAlign: 'center' }}>
                    <Typography variant="h5">{t('signup.step9.code')}</Typography>
                    <InputComponent
                      customStyles={{
                        width: '210px',
                        height: '40px',
                        borderRadius: '4px',
                        marginTop: '15px',
                      }}
                      label={t('signup.step9.promoCode')}
                      type="text"
                    />
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
        </ListItem>
        <ListItem>
          <MainCard border={true} sx={{ width: '100%' }}>
            <Grid container spacing={3}>
              <Grid item xs={12} md={6}>
                <Link
                  sx={{
                    typography: { xs: 'h5' },
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  color="grey"
                  onClick={sendEmailWithAttachments}
                >
                  {t('termsAndCondition')}
                </Link>
              </Grid>
              <Grid item xs={12} md={6} alignItems="flex-end">
                <Stack
                  alignItems="flex-end"
                  justifyContent="space-between"
                  sx={{ flexDirection: { xs: 'row', md: 'column' } }}
                >
                  <Typography
                    color="text.secondary"
                    sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}
                  >
                    {t('signup.step9.total')}
                  </Typography>
                  <Typography variant="h1" sx={price}>
                    {currencyFormatter(priceInfo.monthly, { code: 'EUR' })}
                  </Typography>
                  <Typography color="text.secondary">
                    {`(${currencyFormatter(priceInfo.annual, { code: 'EUR' })} ${t('signup.step9.year')})`}
                  </Typography>
                  <Typography
                    color="text.secondary"
                    sx={{ typography: { xs: 'h4', md: 'h5' }, textAlign: { md: 'right' } }}
                  >
                    {t('signup.step9.TTC')}
                  </Typography>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </ListItem>
        <ListItem>
          <Grid container spacing={3} sx={{ textAlign: 'right' }}>
            <Grid item xs={12} md={12}>
              <Button
                disabled={selected.length === 0}
                variant="contained"
                color="primary"
                sx={{ width: { xs: '100%', md: 250 } }}
                onClick={handleCheckout}
              >
                {t('buttons.next')}
              </Button>
            </Grid>
          </Grid>
        </ListItem>
      </List>
    </>
  );
};

export default Step9;
