// project import
import backofficeMenu from './backoffice-menu';

// ==============================|| MENU ITEMS ||============================== //

const menuItemsBackOfficel = {
  items: [backofficeMenu]
};

export default menuItemsBackOfficel
