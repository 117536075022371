import serviceLinks from "../common/Links.json"
import ApiHelper from "../common/ApiHelper";
import Headers from "../common/types/Headers";
import Constants from "../common/Constants";
const headers = new Headers();

class AccountService {
    static getAccountList() {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = '/administrators/';
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('GET', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static createNewAccount(account) {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = '/administrators/create';
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('POST', url, account, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static updateAccount(account) {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = '/administrators/update';
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('PUT', url, account, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }

    static deleteAccount(username) {
        var clientId = Constants.CLIENT_ID_SAMPLE; // TODO: Get from loggedin client
        if(clientId){
            const url = '/administrators/'+username;
            let params = {};
            let hdrs = headers.GetHeaders();
            return new Promise((resolve, reject) => {
                ApiHelper('DELETE', url, params, hdrs                
                ).then(data => {
                    resolve(data);              
                }).catch(err => {
                    reject(err);
                });
            });                
        }   
    }
    

}

export default AccountService;