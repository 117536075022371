import PropTypes from 'prop-types';
import useMediaQuery from '@mui/material/useMediaQuery';
import Chip from '@mui/material/Chip';
import Grid from '@mui/material/Grid';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { useEffect, useRef, useState } from 'react';
import { Button, ListItemText, TextField } from '@mui/material';

// project import
import MainCard from 'components/MainCard';

// assets
import dateFormat from "dateformat";
import { format as currencyFormatter } from 'currency-formatter';
import { fetcher, fetcherPost } from 'utils/axios';
import dayjs from 'dayjs';
import FileUploaded from 'components/FileUploaded';
import { Label } from '@mui/icons-material';
import MultiFileUpload from 'components/third-party/dropzone/MultiFile';
import { uploadDocuments } from 'services/DocumentsService';

export default function ExpandingClaimDetailBO({ data }) {
  const uploadComponent = useRef(null);
  const { t } = useTranslation();
  const matchDownMD = useMediaQuery((theme) => theme.breakpoints.down('md'));
  const [policyDetail, setPolicyDetail] = useState();
  const [comment, setComment] = useState('');
  const [localFiles, setLocalFiles] = useState([]);
  const [fileRecords, setFileRecords] = useState([]);
  const [filesError, setFilesError] = useState(false);
  const [showUploads, setShowUploads] = useState(true)
  const [amount, setAmount] = useState(''); // State for payment amount

  useEffect(() => {
    // Get policy details
    fetcher(`/policies/detail/${data.policy_id}`).then(data => setPolicyDetail(data));
  }, [data]);

  const renderStatus = (value) => {
    switch (value) {
      case 'OPEN':
        return <Chip color="primary" label="En cours" size="Default" variant="light" />; 
      case 'REJECTED':
        return <Chip color="error" label="Refusé" size="Default" variant="light" />; 
      case 'APPROVED':
        return <Chip color="success" label="Accepté" size="Default" variant="light" />; 
      case 'AWAITING_CLIENT':
        return <Chip color="secondary" label="En attente" size="Default" variant="light" />; 
        case 'CANCELLED':
          return <Chip color="info" label="Annulé" size="Default" variant="light" />; 
      default:
        return <Chip color="default" label={`Statut inconnu: ${value}`} size="Default" variant="light" />; 
    }
  };

  const renderPolicyStatus = (value) => {
    switch (value) {
      case 'EXPIRED':
        return <Chip color="error" label={t('claims.status.expired')} size="Default" variant="light" />;
      case 'ACTIVE':
        return <Chip color="success" label={t('claims.status.active')} size="Default" variant="light" />;
      default:
        return <Chip color="primary" label={t(`status.${value.toLowerCase()}`)} size="Default" variant="light" />;
    }
  };

  const claimStatusHistory = (histories) => {
    return (
      <List disablePadding>
        {histories.map(history => <ListItem divider={!matchDownMD}>
          Set status <strong>{history.new_status}</strong> with comment <strong>{history.comment}</strong> at <strong>{history.updated_at}</strong>
        </ListItem>)}
      </List>)
  }



  const renderClaimButton = (claim_id, value) => {

    switch (value) {
        case 'REJECTED':
        return <>
          <Typography sx={{ mb: 2 }}>
            Commentaire
          </Typography>
          <TextField
            variant="outlined"
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
          <Grid container spacing={1} style={{ marginLeft: -8 }}>
            <Grid item xs={3} md={3}>
              <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={() => approveClaims(claim_id)}>{t('claims.buttons.approveClaim')}</Button>
            </Grid>
            <Grid item xs={3} md={3}>
              <Button disabled={comment.length == 0} variant="contained" color="secondary" style={{ width: '100%' }} onClick={() => awaitClient(claim_id)}>{t('claims.buttons.awaitClient')}</Button>
            </Grid>
          </Grid>
        </>;
      case 'APPROVED':
        return <>
          <Typography sx={{ mb: 2 }}>
            Commentaire
          </Typography>
          <TextField
            variant="outlined"
            value={comment}
            onChange={e => setComment(e.target.value)}
          />
          <Grid container spacing={1} style={{ marginLeft: -8 }}>
            <Grid item xs={3} md={3}>
              <Button disabled={comment.length == 0} variant="contained" color="error" style={{ width: '100%' }} onClick={() => rejectClaim(claim_id)}>{t('claims.buttons.rejectClaim')}</Button>
            </Grid>
            <Grid item xs={3} md={3}>
              <Button disabled={comment.length == 0} variant="contained" color="secondary" style={{ width: '100%' }} onClick={() => awaitClient(claim_id)}>{t('claims.buttons.awaitClient')}</Button>
            </Grid>
          </Grid>
        </>;
      
      case 'OPEN':
      case 'AWAITING_CLIENT':
      default:
        return (
          <>
            <Typography sx={{ mb: 2 }}>
              Commentaire
            </Typography>
            <TextField
              variant="outlined"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
            {/* <TextField
              variant="outlined"
              label="Payment Amount"
              type="number"
              value={amount}
              onChange={(e) => setAmount(e.target.value)}
              style={{ marginTop: 8 }}
            /> */}
            <Grid container spacing={1} style={{ marginLeft: -8 }}>
              {/* <Grid item xs={3} md={3}>
                <Button
                  variant="contained"
                  color="primary"
                  style={{ width: '100%' }}
                  onClick={() => approveAndPayClaim(claim_id, amount)}
                >
                  {t('claims.buttons.approveClaim')}
                </Button>
              </Grid>
               */}
              <Grid item xs={3} md={3}>
                <Button variant="contained" color="primary" style={{ width: '100%' }} onClick={() => approveClaims(claim_id)}>{t('claims.buttons.approveClaim')}</Button>
              </Grid>
              <Grid item xs={3} md={3}>
                <Button
                  disabled={comment.length === 0}
                  variant="contained"
                  color="error"
                  style={{ width: '100%' }}
                  onClick={() => rejectClaim(claim_id)}
                >
                  {t('claims.buttons.rejectClaim')}
                </Button>
              </Grid>
              <Grid item xs={3} md={3}>
              <Button disabled={comment.length == 0} variant="contained" color="secondary" style={{ width: '100%' }} onClick={() => awaitClient(claim_id)}>{t('claims.buttons.awaitClient')}</Button>
            </Grid>
            </Grid>
          </>
        );
    }
  };

  const generateRelationshipLabel = (relationship) => {
    switch (relationship) {
      case "client":
        return "Client lui-même";
      case "parent":
        return "Parent";
      case "spouse":
        return "Conjoint(e)";
      case "sibling":
        return "Frère/soeur";
      case "other":
        return "Autre";
      default:
        return "Client";
    }
  };

  const approveClaims = (claim_id) => {
    fetcherPost(['/claims/approve-claim', { claim_id, comment }]).then((data => {
      console.log(data)
      alert('Vous avez approuvé le sinistre!')
      window.location.reload();
    }))
  }

  const rejectClaim = (claim_id) => {
    fetcherPost(['/claims/reject-claim', { claim_id, comment }]).then((data => {
      console.log(data)
      alert('Vous avez rejeté le sinistre!')
      window.location.reload();
    }))
  }

  const awaitClient = (claim_id) => {
    fetcherPost(['/claims/await-client-claim', { claim_id, comment }]).then((data => {
      console.log(data)
      alert('Vous avez demandé au client de mettre à jour le sinistre!')
      window.location.reload();
    }))
  }

  const underConstruction = () => {
    alert(t('claims.alerts.underConstruction'));
  };

  const handleUploadFiles = ({ data, isError = false }) => {
    setFileRecords(data);
    setFilesError(isError);
  };
  const handleRemoveFile = (fileName) => {
    setLocalFiles((prev) => prev.filter((file) => file.name !== fileName));
  }


  const handleAddFiles = (newFiles) => {
    setLocalFiles((prev) => [...prev, ...newFiles]);
  };

  const doUpload = async () => {
    setShowUploads(false)
    const uploadResponse = await uploadDocuments(localFiles, data.id);
    let uploadFiles = uploadResponse.data.records
    for (let i = 0; i < uploadFiles.length; i++) {
      data.documents.push(uploadFiles[i])
    }

    setLocalFiles([])
    setTimeout(() => setShowUploads(true), 1000)
  }

  const calculateAmount = () => {
    let amount = 0
    amount += data.request_amount
    for (let i = 0; i < data.child_claims.length; i++) {
      amount += data.child_claims[i].request_amount
    }
    return amount
  }

  const approveAndPayClaim = (claim_id, amount) => {
    if (!amount) {
      alert('Please enter a valid amount.');
      return;
    }

    fetcherPost(['/claims/accept-claim', { claimId: claim_id, amount }])
      .then((data) => {
        console.log('Response:', data); // Debugging
        alert('Sinistre approuvée, et paiement envoyé avec succès!');
        window.location.reload();
      })
      .catch((error) => {
        console.error('Error in API call:', error.response || error.message);
        alert("Échec de l'approbation et du paiement du sinistre.");
      });
  };

  return (
    <Grid container spacing={2.5} sx={{ pl: { xs: 0, sm: 5, md: 6, lg: 10, xl: 12 } }} style={{ paddingTop: 25 }}>
      {/* <Grid item xs={12} sm={12} md={12} xl={12}>         */}
      <Grid xs={12} sm={4} md={4} xl={4}>
        <MainCard title={t('claims.policyDetails')}>
          {policyDetail && (
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.policyNumber')}</Typography>
                      <Typography variant="h3">
                        {policyDetail.amendment_number}
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    {/*<Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.status')}</Typography>
                      <Typography>{renderPolicyStatus('ACTIVE')}</Typography>
                    </Stack>*/}
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">{t('claims.registrationDate')}</Typography>
        <Typography>{dateFormat(new Date(policyDetail.payment_date), 'dd-MM-yyyy  HH:mm')}</Typography>
      </Stack>
    </Grid>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">{t('claims.expirationDate')}</Typography>
        <Typography>
          {policyDetail.expiration_date
            ? dateFormat(new Date(policyDetail.expiration_date), 'dd-MM-yyyy  HH:mm') 
            : dateFormat(new Date(new Date(policyDetail.payment_date).setFullYear(new Date(policyDetail.payment_date).getFullYear() + 1)), 'dd-MM-yyyy  HH:mm')} 
        </Typography>
      </Stack>
    </Grid>
  </Grid>
</ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.paymentFrequency')}</Typography>
                      <Typography>{policyDetail.payment_frequency}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          )}
        </MainCard>
      </Grid>
      <Grid xs={12} sm={7} md={7} xl={7} style={{ marginLeft: 10 }}>
        <Stack spacing={2.5}>
          <MainCard title={t('claims.claimDetails')}>
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimNumber')}</Typography>
                      <Typography variant="h3">{data.claim_reference_number}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimAmount')}</Typography>
                      <Typography variant="h3">{currencyFormatter(data.request_amount, { code: 'EUR' })}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.createdDate')}</Typography>
                      <Typography>{dateFormat(data.created_at, 'dd-mm-yyyy HH:MM')}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.occurrenceDate')}</Typography>
                      <Typography>{dateFormat(data.occurence_date, 'dd-mm-yyyy')}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimStatus')}</Typography>
                      <Typography>{renderStatus(data.claim_status_id)}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimType')}</Typography>
                      <Typography>{data.claim_type_id}</Typography>
                      {data.child_claims.map(child_claim => <Typography>{child_claim.claim_type_id}</Typography>)}
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimContent')}</Typography>
                      <Typography>{data.claim_cause}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">Déclarant :</Typography>
        <Typography>
          {data.claimant_first_name || data.client.first_name} {data.claimant_last_name || data.client.last_name}
        </Typography>
      </Stack>
    </Grid>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">Email du déclarant :</Typography>
        <Typography>{data.claimant_email || data.client.email}</Typography>
      </Stack>
    </Grid>
  </Grid>
</ListItem>
<ListItem divider={!matchDownMD}>
  <Grid container spacing={3}>
    <Grid item xs={12} md={6}>
      <Stack spacing={0.5}>
        <Typography color="secondary">Numéro du déclarant :</Typography>
        <Typography>{data.claimant_phone_number || data.client.phone_number}</Typography>
      </Stack>
    </Grid>
  
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">Relation avec le déclarant :</Typography>
                      <Typography>{generateRelationshipLabel(data.claimant_relationship)}</Typography>
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.evidenceDocuments')}</Typography>
                      {data.documents.length > 0 && data.documents.map((document) => (
                        <FileUploaded file={document} key={document.id} />
                      ))}

                      {data.child_claims.map((child) =>
                        child.documents.length > 0 && child.documents.map((document) => (
                          <FileUploaded file={document} key={document.id} />
                        )
                        ))}
                      
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.claimHistory')}</Typography>
                      <Typography>
                        <List sx={{ width: '100%', bgcolor: 'background.paper' }}>
                          {Array.isArray(data.claim_history) && data.claim_history.length && data.claim_history.map(item => {
                            const formattedDate = dayjs(item.updated_at).format('MMM DD YYYY h:mm');
                            return (
                              <ListItem key={item.id} sx={{ px: 0, justifyContent: 'space-between' }}>
                               { renderStatus(item.new_status)}
                                 
                                <ListItemText
                                  primary={formattedDate}
                                  sx={{ display: 'flex', justifyContent: 'center' }}
                                />
                                <ListItemText
                                  primary={item.comment}
                                  sx={{ display: 'flex', justifyContent: 'end' }}
                                />
                              </ListItem>
                            );
                          })}
                        </List>
                      </Typography>
                    </Stack>
                  </Grid>
                </Grid>
                </ListItem>

                <ListItem divider={!matchDownMD}>
    <Grid container spacing={3}>
      <Grid item xs={12} md={12}>
        <Stack spacing={0.5}>
          <Typography color="secondary">Ajouter des documents:</Typography>
          {showUploads && (
            <>
              <MultiFileUpload
                onUpload={handleUploadFiles}
                onRemoveFile={handleRemoveFile}
                onAddFile={handleAddFiles}
              />
              <Button
                disabled={localFiles.length === 0}
                variant="contained"
                color="primary"
                style={{ width: '100%', marginTop: 10 }}
                onClick={doUpload}
              >
                Ajouter des documents
              </Button>
            </>
          )}
          {!showUploads && (
            <img
              style={{ width: 50 }}
              src="https://cdn.pixabay.com/animation/2023/05/02/04/29/04-29-03-511_512.gif"
              alt="Uploading..."
            />
          )}
        </Stack>
      </Grid>
    </Grid>
  </ListItem>
                 
              {data.child_claims.length > 0 && <ListItem divider={!matchDownMD}>
                <Grid container spacing={3} style={{ marginTop: 5, marginLeft: 5, display: 'flex', flexDirection: 'column' }}>
                  <Typography color="secondary">Sinistres groupés</Typography>
                  <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                    {data.child_claims.map(child_claim =>
                      <Grid item xs={3} md={3}>
                        <Chip color="primary" label={child_claim.claim_reference_number} size="Default" variant="light" />
                      </Grid>
                    )}
                  </div>
                </Grid>
              </ListItem>}
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      {renderClaimButton(data.id, data.claim_status_id)}
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </MainCard>
        </Stack>
      </Grid>
      {/* </Grid> */}
      {/* <Grid item xs={12} sm={5} md={5} xl={5}>
        <Stack spacing={2.5}>
          <MainCard title={t('claims.claimAIResult')}>
            <List sx={{ py: 0 }}>
              <ListItem divider={!matchDownMD}>
                <Grid container spacing={3}>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.extracted_entities')}</Typography>
                      <Typography>{data.extracted_data.extracted_entities.args.date}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.destinataire_adresse')}</Typography>
                      <Typography>{data.extracted_data.extracted_entities.args.destinataire_adresse}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.expediteur adresse')}</Typography>
                      <Typography>{data.extracted_data.extracted_entities.args['expediteur adresse']}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.motif')}</Typography>
                      <Typography>{data.extracted_data.extracted_entities.args['motif']}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.signature')}</Typography>
                      <Typography>{data.extracted_data.extracted_entities.args['signature']}</Typography>
                    </Stack>
                  </Grid>

                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.document_type')}</Typography>
                      <Typography>{data.input_data['document_type']}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.policy_start_date')}</Typography>
                      <Typography>{data.input_data['policy_start_date']}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.date_valid')}</Typography>
                      {data.validation_result.date_valid && <img src="https://media.istockphoto.com/id/1435212785/vector/check-mark-icon-vector-design-template-in-white-background.jpg?s=612x612&w=0&k=20&c=2_fssLF8cYr_7TkeMGtpf5WQQIMTPr4r6GLadBickf4=" style={{ width: 48 }} />}
                      {!data.validation_result.date_valid && <img src="https://img.icons8.com/m_rounded/512/FA5252/checked-2.png" style={{ width: 36, marginLeft: 7 }} />}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.motif_valid')}</Typography>
                      {data.validation_result.motif_valid && <img src="https://media.istockphoto.com/id/1435212785/vector/check-mark-icon-vector-design-template-in-white-background.jpg?s=612x612&w=0&k=20&c=2_fssLF8cYr_7TkeMGtpf5WQQIMTPr4r6GLadBickf4=" style={{ width: 48 }} />}
                      {!data.validation_result.motif_valid && <img src="https://img.icons8.com/m_rounded/512/FA5252/checked-2.png" style={{ width: 36, marginLeft: 7 }} />}
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.score')}</Typography>
                      <Typography>{data.validation_result.score}</Typography>
                    </Stack>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Stack spacing={0.5}>
                      <Typography color="secondary">{t('claims.structure_valid')}</Typography>
                      {data.validation_result.structure_valid && <img src="https://media.istockphoto.com/id/1435212785/vector/check-mark-icon-vector-design-template-in-white-background.jpg?s=612x612&w=0&k=20&c=2_fssLF8cYr_7TkeMGtpf5WQQIMTPr4r6GLadBickf4=" style={{ width: 48 }} />}
                      {!data.validation_result.motif_valid && <img src="https://img.icons8.com/m_rounded/512/FA5252/checked-2.png" style={{ width: 36, marginLeft: 7 }} />}
                    </Stack>
                  </Grid>
                </Grid>
              </ListItem>
            </List>
          </MainCard>
        </Stack>
      </Grid> */}
    </Grid>
  );
}

ExpandingClaimDetailBO.propTypes = { data: PropTypes.any };