import React, { useState, useEffect } from 'react';
import { styled } from '@mui/system';
import { useTranslation } from 'react-i18next';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentDissatisfiedIcon from '@mui/icons-material/SentimentDissatisfied';
import { Button, Grid, List, ListItem, Stack, TextField, Typography, Box } from '@mui/material';
import Constants from 'common/Constants';
import IconButton from 'components/@extended/IconButton';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import TypeSwitcher from 'components/frontOffice/HomePageComponents/TypeSwitcher';

const MoodIcons = styled('div')({
  display: 'flex',
  justifyContent: 'space-between',
  width: '100px',
  margin: '20px auto',
});

const MoodIcon = styled('div')(({ isSelected }) => ({
  cursor: 'pointer',
  color: isSelected ? '#EB3D9F' : '#675f63',
  '& svg': {
    fontSize: '40px',
  },
}));

const ClientInfoStep = ({ nextStep, prevStep }) => {
  const { t } = useTranslation();
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [mood, setMood] = useState('');
  const [selection, setSelection] = useState(Constants.CLIENT_TYPE.PERSONAL);

  // Load client object from sessionStorage when component mounts
  useEffect(() => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};

    if (clientObject.first_name) setFirstName(clientObject.first_name);
    if (clientObject.last_name) setLastName(clientObject.last_name);
    if (clientObject.mood) setMood(clientObject.mood);
    if (clientObject.client_type) setSelection(clientObject.client_type);
  }, []);

  // Function to update session storage whenever any field changes
  const updateClientObject = (key, value) => {
    const clientObject = JSON.parse(sessionStorage.getItem(Constants.CLIENT_OBJECT)) || {};
    const updatedClientObject = {
      ...clientObject,
      [key]: value,
    };
    sessionStorage.setItem(Constants.CLIENT_OBJECT, JSON.stringify(updatedClientObject));
  };

  useEffect(() => {
    updateClientObject('first_name', firstName);
    updateClientObject('last_name', lastName);
    updateClientObject('mood', mood);
    updateClientObject('client_type', selection);
  }, [firstName, lastName, mood, selection]);

  const toggleSelection = () => {
    const newSelection = selection === Constants.CLIENT_TYPE.PERSONAL ? Constants.CLIENT_TYPE.PROFESSIONAL : Constants.CLIENT_TYPE.PERSONAL;
    setSelection(newSelection);
  };

  const handleNextClick = () => {
    nextStep({ first_name: firstName, last_name: lastName, mood });
  };

  const handleBackClick = () => {
    prevStep();
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
      <Box sx={{ position: 'relative', width: '100%', maxWidth: '800px' }}>
        <IconButton 
          onClick={handleBackClick} 
          style={{ position: 'absolute', left: 0, top: "50%" }}
        >
          <ArrowBackIcon style={{ width: 30, height: 30 }} />
        </IconButton>
        <Box sx={{ textAlign: 'center', marginLeft: '46px', marginTop:'30px' }}>
          {/*<TypeSwitcher selection={selection} toggleSelection={toggleSelection} homePage={true} />*/}
          <Typography variant="h2" gutterBottom>
            {t('signup.step1.title1')}
          </Typography>
          <List style={{ marginBottom: 10 }} sx={{ py: 0 }}>
            <ListItem>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={11}>
                  <Stack spacing={0.5}>
                    <TextField
                      id="outlined-basic"
                      placeholder={t('signup.step1.firstName')}
                      value={firstName}
                      onChange={(e) => setFirstName(e.target.value)}
                      sx={{
                        '& .MuiInputLabel-root': { fontSize: '1.5rem' },
                        '& .MuiOutlinedInput-root': { fontSize: '1.5rem' },
                      }}
                      fullWidth
                    />
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
            <ListItem>
              <Grid container spacing={3} justifyContent="center">
                <Grid item xs={12} md={11}>
                  <Stack spacing={0.5}>
                    <TextField
                      id="outlined-basic"
                      placeholder={t('signup.step1.lastName')}
                      value={lastName}
                      onChange={(e) => setLastName(e.target.value)}
                      sx={{
                        '& .MuiInputLabel-root': { fontSize: '1.5rem' },
                        '& .MuiOutlinedInput-root': { fontSize: '1.5rem' },
                      }}
                      fullWidth
                    />
                  </Stack>
                </Grid>
              </Grid>
            </ListItem>
          </List>
          <Typography variant="h4">{t('signup.step1.moodQuestion')}</Typography>
          <MoodIcons>
            <MoodIcon isSelected={mood === 'happy'} onClick={() => setMood('happy')}>
              <SentimentSatisfiedAltIcon />
            </MoodIcon>
            <MoodIcon isSelected={mood === 'sad'} onClick={() => setMood('sad')}>
              <SentimentDissatisfiedIcon />
            </MoodIcon>
          </MoodIcons>
          <Stack direction='row' alignItems='center' justifyContent='center' columnGap={2} >
            <Button
              size='large'
              disabled={!firstName || !lastName || !mood}
              variant="contained"
              color="primary"
              style={{ width: 250 }}
              onClick={handleNextClick}
            >
              {t('buttons.next')}
            </Button>
          </Stack>
        </Box>
      </Box>
    </Box>
  );
};

export default ClientInfoStep;
