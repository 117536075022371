// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

// project-imports
import AuthWrapper from 'sections/auth/AuthWrapper';
import AuthForgotPassword from 'sections/auth/auth-forms/AuthForgotPassword';
import { useTranslation } from 'react-i18next';

// ================================|| FORGOT PASSWORD ||================================ //

export default function ForgotPassword() {
  const { t } = useTranslation();

  return (
    <AuthWrapper>
      <Grid container spacing={3}>

        {/* Centered Title */}
        <Grid item xs={12} sx={{ textAlign: 'center' }}>
          <Typography variant="h3">{t('login.forgotPassword')}</Typography>
        </Grid>

        <Grid item xs={12}>
          <AuthForgotPassword />
        </Grid>
      </Grid>
    </AuthWrapper>
  );
}
