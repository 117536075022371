import axios from 'axios';
import Constants from 'common/Constants';
const axiosServices = axios.create({ baseURL: Constants.APP_API_BASE_URL });
// ==============================|| AXIOS - FOR MOCK SERVICES ||============================== //

axiosServices.interceptors.request.use(
  async (config) => {
    config.headers['Content-Type'] = 'application/json';
    config.headers['access-api-key'] = Constants.ACCESS_API_KEY_SAMPLE;    
    const accessToken = localStorage.getItem('CLIENT_AUTH_TOKEN');
    if (accessToken) {
      config.headers['access-auth-token'] = accessToken;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosServices.interceptors.response.use(
  (response) => response,
  (error) => {
    // if (error.response.status === 401 && !window.location.href.includes('/login')) {
    //   window.location.pathname = '/maintenance/500';
    // }
    if(error.response.status === 401 && !window.location.href.includes('/login') && !window.location.href.includes('/backoffice')) {
      window.location.href = '/login';
    }

    return Promise.reject((error.response && error.response.data) || 'Wrong Services');
  }
);

export default axiosServices;

export const fetcher = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.get(url, { ...config });

  return res.data;
};

export const fetcherPost = async (args) => {
  const [url, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.post(url, { ...config });

  return res.data;
};

export const fetcherPut = async (args) => {
  const [url, data, config] = Array.isArray(args) ? args : [args];

  const res = await axiosServices.put(url, data, { ...config });

  return res.data;
};

export const fetcherPatch = async (args) => {
    const [url, data, options] = Array.isArray(args) ? args : [args];

    const res = await axiosServices({
        method: 'PATCH',
        url,
        data,
        ...options,
    });

    return res.data;
};
